import styled from 'styled-components';

import { pulse } from 'theme/animations';

export const StyledPulsingHeartContainer = styled.span`
  display: inline-block;
  animation: ${pulse} 2.5s infinite;
  margin: 5px;
  color: ${({ theme }) => theme.colors.white};
`;
