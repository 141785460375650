import React, { FC, useState, useEffect } from 'react';
import { AppProps } from 'next/app';
import { Router, useRouter } from 'next/router';
import { ThemeProvider } from 'styled-components';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import debounce from 'debounce';
import 'swiper/swiper-bundle.min.css';

import { Header } from 'components/molecules/Header/Header';
import { Footer } from 'components/Footer/Footer';
import { Cookies } from 'components/Cookies/Cookies';
import { ContactWidget } from 'components/ContactWidget/ContactWidget';
import { Portal } from 'components/Portal/Portal';
import MetaTags from 'components/MetaTags/MetaTags';
import PageTitle from 'components/PageTitle/PageTitle';
import { PreviewBanner } from 'components/PreviewBanner/PreviewBanner';

import { GlobalStyle } from 'theme/global-styles';
import theme from 'theme/theme';
import 'theme/fonts.css';
import { GTMPageView, GTMscript } from 'utils/gtm';
import config from 'config';
import CookiesHandler from 'utils/Cookies';
import HeaderThemeProvider from 'utils/HeaderThemeContext';
import FooterSpaceProvider from 'utils/FooterSpaceContext';
import isBrowser from 'utils/isBrowser';
import fixVH from 'utils/fixVH';
import { getFullUrl } from 'utils/getFullUrl';
import Head from 'next/head';

const { COOKIES_ACCEPTED_KEY } = config;

SwiperCore.use([Navigation, Pagination]);

export const App: FC<AppProps> = ({ Component, pageProps }) => {
  const { page, menuStructure, footerData, isPreview, clutchWidget, contactForm, popupWidget, logo } = pageProps;
  const [isCookiePolicyAccepted, setIsCookiePolicyAccepted] = useState<boolean | null>(null);
  const { pathname, locales, asPath } = useRouter();
  const pathsWithoutContactWidget = [config.ROUTES.CAREERS.href, config.ROUTES.CONTACT.href];
  const shouldShowContactWidget = !pathsWithoutContactWidget.some((path) => pathname.includes(path));

  useEffect(() => {
    const handleRouteChange = (url: string) => GTMPageView(url);
    Router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  useEffect(() => {
    const isAccepted = CookiesHandler.getCookie(COOKIES_ACCEPTED_KEY);
    if (isAccepted) {
      setIsCookiePolicyAccepted(isAccepted);
    }
  }, []);

  const handleAnswerClick = (answer: boolean) => {
    setIsCookiePolicyAccepted(answer);
  };

  useEffect(() => {
    if (isBrowser) {
      fixVH();
      const updateVhDeb = debounce(fixVH, 100);
      window.addEventListener('resize', updateVhDeb);

      return () => {
        window.removeEventListener('resize', updateVhDeb);
      };
    }
  }, []);

  return (
    <>
      {isCookiePolicyAccepted && GTMscript}
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <HeaderThemeProvider defaultTheme={{ mobile: 'light', desktop: 'light' }}>
          <FooterSpaceProvider>
            {page?.title && <PageTitle title={page.title} />}
            {page?.metaTags && (
              <MetaTags
                metaTags={page.metaTags.meta}
                ogImage={page.metaTags.ogImage}
                data={page.metaTags.data}
                link={page.metaTags.link}
              />
            )}
            <Head>
              <link rel="alternate" hrefLang="x-default" href={getFullUrl(asPath)} />
              {locales.map((locale) => {
                const localeHref = locale === 'en' ? '' : `/${locale}`;
                const pathHref = asPath === '/' ? '' : asPath;

                return (
                  <link key={locale} rel="alternate" hrefLang={locale} href={getFullUrl(`${localeHref}${pathHref}`)} />
                );
              })}
            </Head>
            {shouldShowContactWidget && (
              <Portal>
                <ContactWidget {...contactForm} {...popupWidget} />
              </Portal>
            )}
            {isPreview && <PreviewBanner />}
            <Header menuStructure={menuStructure} logo={logo} />
            <Component {...pageProps} />
            <Footer {...footerData} clutch={clutchWidget} />
          </FooterSpaceProvider>
        </HeaderThemeProvider>
        {isCookiePolicyAccepted === null && <Cookies onAnswerClick={handleAnswerClick} />}
      </ThemeProvider>
    </>
  );
};

export default App;
