import React, { ComponentType, ElementType, forwardRef, ReactNode } from 'react';

import {
  StyledH1,
  StyledH1Highlighted,
  StyledH2,
  StyledH3,
  StyledH3bigger,
  StyledH4bigger,
  StyledH4,
  StyledH5,
  StyledBody1,
  StyledBody2,
  StyledCaption,
  StyledCounter,
  StyledSubtitle,
  StyledTypography,
  StyledLabel,
  StyledBody3,
  StyledH6,
  StyledH1Small
} from './Typography.styles';

type Variant =
  | 'h1'
  | 'h1small'
  | 'h1Highlighted'
  | 'h2'
  | 'h3'
  | 'h3bigger'
  | 'h4bigger'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'body1'
  | 'body2'
  | 'body3'
  | 'counter'
  | 'caption'
  | 'subtitle'
  | 'label';

type Weight = '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900';

type Align = 'center' | 'left' | 'right' | 'inherit';

type Transform = 'uppercase' | 'capitalize' | 'lowercase';

export type TypographyProps = {
  renderAs?: ElementType;
  children: ReactNode;
  color?:
    | 'primary'
    | 'liki-green'
    | 'secondary'
    | 'primaryGray'
    | 'secondaryGray'
    | 'tertiaryGray'
    | 'quinary'
    | 'darkGreen'
    | 'darkerGreen'
    | 'green_200'
    | 'green_300'
    | 'green_400'
    | 'inherit';
  variant: Variant;
  weight?: Weight;
  transform?: Transform;
  align?: Align;
  ellipsisLines?: number;
  title?: string;
  id?: string;
  className?: string;
};

const componentsMap: { [key in Variant]: { as: ElementType; Component: ComponentType; weight: Weight } } = {
  h1: { as: 'h1', Component: StyledH1, weight: '600' },
  h1small: { as: 'h1', Component: StyledH1Small, weight: '700' },
  h1Highlighted: { as: 'h1', Component: StyledH1Highlighted, weight: '600' },
  h2: { as: 'h2', Component: StyledH2, weight: '600' },
  h3: { as: 'h3', Component: StyledH3, weight: '600' },
  h3bigger: { as: 'h3', Component: StyledH3bigger, weight: '700' },
  h4bigger: { as: 'h4', Component: StyledH4bigger, weight: '300' },
  h4: { as: 'h4', Component: StyledH4, weight: '600' },
  h5: { as: 'h5', Component: StyledH5, weight: '600' },
  h6: { as: 'h6', Component: StyledH6, weight: '600' },
  body1: { as: 'p', Component: StyledBody1, weight: '300' },
  body2: { as: 'p', Component: StyledBody2, weight: '300' },
  body3: { as: 'p', Component: StyledBody3, weight: '300' },
  counter: { as: 'h1', Component: StyledCounter, weight: '300' },
  caption: { as: 'span', Component: StyledCaption, weight: '400' },
  subtitle: { as: 'span', Component: StyledSubtitle, weight: '400' },
  label: { as: 'span', Component: StyledLabel, weight: '400' }
};

const Typography = forwardRef<HTMLElement, TypographyProps>(
  (
    { renderAs, children, color = 'inherit', variant, weight, align, transform, ellipsisLines, title, id, className },
    ref
  ) => {
    const component = componentsMap[variant];
    const Component = component.Component as typeof StyledTypography;
    const { as, weight: defaultWeight } = component;

    return (
      <Component
        id={id}
        ref={ref}
        className={className}
        transform={transform}
        color={color}
        as={renderAs || as}
        weight={weight || defaultWeight}
        align={align}
        ellipsisLines={ellipsisLines}
        title={title}
      >
        {children}
      </Component>
    );
  }
);

export default Typography;
