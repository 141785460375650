type Space = number | 'auto';

const DEFAULT_SPACING = 8;
const DEFAULT_UNIT = 'px';

const getSpace = (multiplier: Space): string => {
  if (multiplier === 0) {
    return '0';
  }

  if (multiplier === 'auto') {
    return 'auto';
  }

  return `${multiplier * DEFAULT_SPACING}${DEFAULT_UNIT}`;
};

const spacing = (...args: [Space, Space?, Space?, Space?]): string =>
  args
    .filter((el) => el !== undefined)
    .map((el) => getSpace(el))
    .join(' ');

export default spacing;
