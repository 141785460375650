import React, { ReactElement } from 'react';
import { useRouter } from 'next/router';

import { LinkedButton } from 'components/atoms/Button/LinkButton/LinkButton';
import { partition } from 'utils/partition';
import { isMenuLink, isMobileVisible, MenuItem } from 'utils/types/Menu';

import { StyledMenu, StyledMenuButtonWrapper, StyledMenuItemsWrapper, StyledMenuList } from '../../Header.styles';
import LanguageSelector from '../../LanguageSelector/LanguageSelector';
import MobileMenuItem from './MobileMenuItem/MobileMenuItem';

type MobileMenuProps = {
  isOpen: boolean;
  menuItems: Array<MenuItem>;
  submenuItemsByParentId: Record<string, Array<MenuItem>>;
};

const MobileMenu = ({ isOpen, menuItems, submenuItemsByParentId }: MobileMenuProps): ReactElement => {
  const { locale } = useRouter();

  const [links, buttons] = partition(menuItems.filter(isMobileVisible), isMenuLink);

  return (
    <StyledMenu isOpen={isOpen}>
      <StyledMenuList>
        <StyledMenuItemsWrapper>
          {links.map(({ id, animation, href, text, menu }) => (
            <div key={`mobile-menu-item-${id}`}>
              {!menu && (
                <MobileMenuItem
                  isOpen={isOpen}
                  animationDuration={animation}
                  href={href}
                  text={text}
                  submenuItems={submenuItemsByParentId[id]}
                />
              )}
            </div>
          ))}
          <LanguageSelector key={locale} isMobile />
        </StyledMenuItemsWrapper>
      </StyledMenuList>
      {buttons.map(({ id, href, text }) => (
        <StyledMenuButtonWrapper key={id} isOpen={isOpen}>
          <LinkedButton href={href} width="100%">
            {text}
          </LinkedButton>
        </StyledMenuButtonWrapper>
      ))}
    </StyledMenu>
  );
};

export default MobileMenu;
