import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { AppLink } from 'components/AppLink/AppLink';

import { withActivePath } from 'helpers/withActivePath';
import { ColorScheme } from 'theme';
import { mq } from 'theme/media';

export const StyledHeaderBox = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 5;
`;

export const StyledHeader = styled.div<{ colorScheme: ColorScheme }>`
  position: relative;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 41px 16px rgba(131, 143, 172, 0.01), 0px 23px 14px rgba(131, 143, 172, 0.05),
    0px 10px 10px rgba(131, 143, 172, 0.09), 0px 3px 6px rgba(131, 143, 172, 0.1), 0px 0px 0px rgba(131, 143, 172, 0.1);
`;

export const StyledButtonWrapper = styled.div`
  display: inline-block;
`;

export const StyledNavBarItems = styled.ul`
  display: none;
  white-space: nowrap;
  list-style: none;

  ${mq.xsLarge`
    display: flex;
    align-items: center;
    gap: 20px;
  `}
`;

export const StyledMenuItem = styled.div<{
  isOpen?: boolean;
  duration?: number;
  isSubmenu?: boolean;
  isSubmenuOpen?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-bottom: ${({ theme }) => theme.spacing(3)};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(-100%)')};
  transition: ${({ duration, isOpen }) =>
    !isOpen
      ? 'all 0s ease-in-out'
      : `transform ${duration}s ease-in-out, 
        opacity 1s ${duration - 0.3}s ease`};
`;

export const StyledNavBarItem = styled.li`
  position: relative;
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing(4)};
`;

export const StyledMenuButtonsWrapper = styled.div`
  display: none;
  margin-left: ${({ theme }) => theme.spacing(11)};

  ${mq.xsLarge`
    display: flex;
    align-items: center;
  `}
`;

export const StyledNavBarLink = styled(AppLink)<{
  active?: boolean;
  withNumbers?: boolean;
  colorScheme?: ColorScheme;
  hasLeftHexagon?: boolean;
}>`
  position: relative;
  display: block;
  font-size: 18px;

  ${({ active }) =>
    active &&
    css`
      font-weight: 700;
    `}

  ${mq.xsLarge`
    color: ${({ theme }) => theme.colors.primaryBlack};
    font-size: 14px;
  `}

  
  ${({ withNumbers }) =>
    withNumbers &&
    css`
      &::before {
        ${({ theme }) => theme.fonts.h4};
        font-weight: 700;
        color: ${({ theme }) => rgba(theme.colors.white, 0.2)};
        content: counter(listCounter, decimal-leading-zero);
        margin-right: ${({ theme }) => theme.spacing(2)};
      }
    `}

  ${({ active, hasLeftHexagon = false }) =>
    active &&
    hasLeftHexagon &&
    css`
      margin-left: ${({ theme }) => theme.spacing(4)};

      &::before {
        color: ${({ theme }) => theme.colors.white};
      }

      &::after {
        top: 0;
        margin: auto 0;
        width: 8px;
        height: 8px;
        left: ${({ theme }) => theme.spacing(-4)};
        transform: rotate(45deg);
      }
    `}

  ${({ withNumbers, colorScheme }) =>
    withNumbers &&
    colorScheme === 'light' &&
    css`
      &::before {
        color: ${({ theme }) => rgba(theme.colors.lighter, 0.4)};
      }
    `}

  ${({ withNumbers, active, colorScheme }) =>
    withNumbers &&
    active &&
    colorScheme === 'light' &&
    css`
      &::before {
        color: ${({ theme }) => theme.colors.light};
      }
    `}

  ${mq.xsLarge`
    &:hover {
      color: ${({ theme }) => theme.colors.green_100};
    }
  `}
`;

export const ActiveStyledNavbarLink = withActivePath(StyledNavBarLink);

export const StyledHeaderContent = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  justify-content: space-between;
  gap: 60px;
  height: 70px;

  ${mq.xsLarge`
  justify-content: space-between;
    height: 74px;
    min-width: 1160px;
  `}
`;

export const StyledHamburgerWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spacing(-2)};

  ${mq.xsLarge`
    display: none;
  `}
`;

export const StyledMenu = styled.div<{ isOpen?: boolean }>`
  position: fixed;
  top: ${({ theme }) => theme.spacing(7)};
  width: 100%;
  height: calc(100% - ${({ theme }) => theme.spacing(7)});
  color: ${({ theme }) => theme.colors.primaryBlack};
  background-color: ${({ theme }) => theme.colors.grey_25};
  transform: ${({ isOpen }) => (isOpen ? 'translateY(0)' : 'translateY(-100%)')};
  transition: transform 0.3s ease;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;

  ${mq.xsLarge`
    display: none;
  `}
`;

export const StyledMenuWrapper = styled.div`
  display: flex;
`;

export const StyledMenuList = styled.div`
  display: flex;
  flex-direction: column;
  counter-reset: listCounter;
  align-self: center;
  width: 100%;
  overflow: auto;
  align-items: center;
`;

export const StyledMenuButtonWrapper = styled.div<{
  isOpen?: boolean;
  duration?: number;
}>`
  display: flex;
  justify-content: center;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(-100%)')};
  transition: ${({ duration, isOpen }) =>
    !isOpen
      ? 'transform 0s ease-in-out, opacity 0s ease-in-out'
      : `transform ${duration}s ease-in-out, opacity 1s ${duration - 0.3}s ease-in-out`};
  transition-delay: ${({ isOpen }) => (isOpen ? '0.3' : '0')};
  padding: ${({ theme }) => theme.spacing(0, 4, 4, 4)};
`;

export const StyledMenuItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(4, 4, 0, 4)};
`;

export const StyledDropdownMenuTrigger = styled(AppLink)<{ isOpenDropdownMenu: boolean; colorScheme?: ColorScheme }>`
  margin-right: ${({ theme }) => theme.spacing(8)};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primaryBlack};

  ${({ isOpenDropdownMenu, theme }) =>
    isOpenDropdownMenu &&
    css`
      color: ${theme.colors.light};
    `}

  &:hover {
    color: ${({ theme }) => theme.colors.light};
  }
`;

export const StyledSelectorArrow = styled.span<{ isMenuOpen: boolean; isSubmenu: boolean }>`
  height: 21px;
  rotate: ${({ isMenuOpen }) => (isMenuOpen ? '0deg' : '270deg')};

  ${({ isSubmenu }) =>
    isSubmenu &&
    css`
      display: none;
    `}
`;

export const StyledSubmenuWrapper = styled.div<{ isMenuOpen: boolean }>`
  padding-left: ${({ theme }) => theme.spacing(2)};

  ${({ isMenuOpen }) =>
    !isMenuOpen &&
    css`
      display: none;
    `}
`;
