import React from 'react';
import Link from 'next/link';

import { PreviewBannerElement, PreviewBannerWrapper } from './PreviewBanner.style';

export const PreviewBanner = () => (
  <PreviewBannerWrapper>
    <Link href="/api/exit-preview">
      <PreviewBannerElement />
    </Link>
  </PreviewBannerWrapper>
);
