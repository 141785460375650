import styled from 'styled-components';

export const WidgetButtonWrapper = styled.div`
  right: 50px;
  bottom: 50px;
  position: fixed;
  display: flex;
  align-items: flex-end;
  direction: ltr;
  z-index: 4;
  visibility: visible;
  animation: 1s ease-out forwards 1;
  flex-direction: column-reverse;
`;
