import React, { FC } from 'react';
import theme from 'theme/theme';

import IconResolver from 'components/atoms/icons/IconResolver/IconResolver';

import {
  WidgetButtonInnerContainer,
  WidgetButtonInnerMask,
  WidgetButtonBlock,
  WidgetButtonPulse,
  WidgetButtonInnerBlock,
  WidgetButtonIconContainer,
  WidgetButtonInnerItem
} from './ButtonWidget.styles';

export const ButtonWidget: FC = () => (
  <WidgetButtonInnerContainer>
    <WidgetButtonInnerMask />
    <WidgetButtonBlock>
      <WidgetButtonPulse />
      <WidgetButtonInnerBlock>
        <WidgetButtonIconContainer>
          <WidgetButtonInnerItem>
            <IconResolver id="button" color={theme.colors.white} />
          </WidgetButtonInnerItem>
        </WidgetButtonIconContainer>
      </WidgetButtonInnerBlock>
    </WidgetButtonBlock>
  </WidgetButtonInnerContainer>
);

export default ButtonWidget;
