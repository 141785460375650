import React, { FC } from 'react';
import Image from '@next/image';

import { FuncButton } from 'components/atoms/Button/FuncButton/FuncButton';
import Typography from 'components/atoms/Typography/Typography';

import useMedia from 'hooks/useMedia';
import config from 'config';
import CookiesHandler from 'utils/Cookies';
import { useTranslations } from 'utils/translations';

import { StyledContainer, StyledDescription, StyledButtons, StyledDescriptionContainer } from './Cookies.styles';

const { COOKIES_ACCEPTED_KEY } = config;

type CookiesProps = {
  onAnswerClick: (isAccepted: boolean) => void;
};

export const Cookies: FC<CookiesProps> = ({ onAnswerClick }) => {
  const { isMobile } = useMedia();
  const width = '136px';
  const t = useTranslations();

  const handleAcceptClick = () => {
    CookiesHandler.setCookie(COOKIES_ACCEPTED_KEY, true, 9999);
    onAnswerClick(true);
  };

  return (
    <StyledContainer>
      <StyledDescriptionContainer>
        <Image src="/icons/liki.svg" alt="FrontendHouse logo on cookie banner" width={69} height={80} />
        <StyledDescription>
          <Typography variant="body2" color="primary">
            {t('cookies-banner-info')}
          </Typography>
        </StyledDescription>
      </StyledDescriptionContainer>
      <StyledButtons>
        <FuncButton width={width} height="40px" variant="secondary-dark" onClick={() => onAnswerClick(false)}>
          {t('cookies-banner-decline')}
        </FuncButton>
        <FuncButton
          width={width}
          height="40px"
          margin={`0 0 0 ${isMobile ? '10%' : '16px'}`}
          onClick={handleAcceptClick}
        >
          {t('cookies-banner-allow')}
        </FuncButton>
      </StyledButtons>
    </StyledContainer>
  );
};
