import React, { createContext, FC, useState } from 'react';

export type FooterSpace = {
  spacing?: number;
};

type Values = {
  spacing: FooterSpace['spacing'];
  setSpacing: (spacing: FooterSpace['spacing']) => void;
};

export const FooterSpaceContext = createContext<Values>({
  spacing: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSpacing: () => {}
});

const FooterSpaceProvider: FC<FooterSpace> = ({ children }) => {
  const [spacing, setSpacing] = useState<FooterSpace['spacing']>(0);

  return <FooterSpaceContext.Provider value={{ spacing, setSpacing }}>{children}</FooterSpaceContext.Provider>;
};

export default FooterSpaceProvider;
