import { RefObject, useEffect, useRef } from 'react';

interface UseClickOutsideOptions {
  disabled?: boolean;
  additionalRefs?: Array<RefObject<HTMLElement>>;
}

const useClickOutside = <T extends HTMLElement = HTMLDivElement>(
  onClickOutside: () => void,
  options?: UseClickOutsideOptions
) => {
  const wrapperRef = useRef<T>(null);
  const { disabled, additionalRefs } = options ?? {};

  useEffect(() => {
    if (disabled) {
      return;
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current === null) {
        return;
      }

      const targetNode = event.target as Node;

      const isWrapperClicked = wrapperRef.current.contains(targetNode);
      const isAdditionalRefClicked = additionalRefs?.some((ref) => ref.current?.contains(targetNode));

      if (!isWrapperClicked && !isAdditionalRefClicked) {
        onClickOutside();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [disabled, additionalRefs]);

  return wrapperRef;
};

export default useClickOutside;
