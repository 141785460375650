import React, { FC } from 'react';

import { useTranslations } from 'utils/translations';

import {
  StyledFormError,
  StyledFormErrorInner,
  StyledFormErrorInnerIcon,
  StyledFormErrorInnerText,
  StyledFormErrorInnerButton,
  StyledContactAction
} from './Error.styles';

export const Error: FC = () => {
  const t = useTranslations();

  return (
    <StyledFormError>
      <StyledFormErrorInner>
        <StyledFormErrorInnerIcon />
        <StyledFormErrorInnerText>{t('contact-form.error')}</StyledFormErrorInnerText>
        <StyledFormErrorInnerText>{t('contact-form.error-action')}</StyledFormErrorInnerText>
        <StyledFormErrorInnerButton>
          <StyledContactAction href="mailto:hello@likims.com" title="Contact email">
            {`${t('contact-form.error-contact')} hello@likims.com`}
          </StyledContactAction>
        </StyledFormErrorInnerButton>
      </StyledFormErrorInner>
    </StyledFormError>
  );
};

export default Error;
