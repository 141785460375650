import React, { Dispatch, ReactElement, SetStateAction, useContext } from 'react';
import { Squash as Hamburger } from 'hamburger-react';
import { useTheme } from 'styled-components';

import { useMinWidthQuery } from 'hooks/useMedia';
import { HeaderContext } from 'utils/HeaderThemeContext';

import { StyledHamburgerWrapper } from '../Header.styles';

type MobileHamburgerProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const MobileHamburger = ({ isOpen, setIsOpen }: MobileHamburgerProps): ReactElement => {
  const isDesktop = useMinWidthQuery('xsLarge');
  const theme = useTheme();
  const { theme: colorSchemeVariants } = useContext(HeaderContext);
  const currentColorScheme = isDesktop ? colorSchemeVariants.desktop : colorSchemeVariants.mobile;

  return (
    <StyledHamburgerWrapper>
      <Hamburger
        color={currentColorScheme === 'light' ? theme.colors.green_100 : theme.colors.white}
        toggle={setIsOpen}
        toggled={isOpen}
        size={18}
        label="Main menu"
      />
    </StyledHamburgerWrapper>
  );
};

export default MobileHamburger;
