import styled from 'styled-components';
import { mq } from 'theme/media';

export const StyledUnorderedList = styled.ul`
  padding: 0;

  & li {
    position: relative;
    max-width: 90%;
    padding-left: ${({ theme }) => theme.spacing(4)};
    margin-bottom: ${({ theme }) => theme.spacing(3)};

    ${mq.large`
        max-width: 100%;
        padding-left: ${({ theme }) => theme.spacing(5)};
        margin-bottom: ${({ theme }) => theme.spacing(3)};
      `}
  }

  & li::before {
    content: url('/icons/listIconMobile.svg');
    position: absolute;
    margin: 0;
    left: 0;

    ${mq.large`
        top: ${({ theme }) => theme.spacing(0.5)};
        content: url('/icons/listIcon.svg');
      `}
  }
`;

export const StyledOrderedList = styled.ol`
  list-style-type: none;
  padding: ${({ theme }) => theme.spacing(2, 0, 0, 4)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  & li {
    position: relative;
    max-width: 90%;
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }

  & li::before {
    position: absolute;
    left: 0;
    top: 0;
    margin-left: ${({ theme }) => theme.spacing(-4)};
    font-weight: 800;
    content: counter(list-item, decimal-leading-zero);
    color: ${({ theme }) => theme.colors.green_100};
  }
`;
