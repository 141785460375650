import { darken } from 'polished';
import { css } from 'styled-components';

import { mq } from './media';

export const articleBody = css`
  ${({ theme }) => theme.fonts.body1};
  font-weight: 300;
  font-size: 20px;
  line-height: 36px;

  & p {
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  }

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 800;
    padding-top: ${({ theme }) => theme.spacing(1)};
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }

  & h1 {
    ${({ theme }) => theme.fonts.h1};
    padding-top: ${({ theme }) => theme.spacing(3)};
    margin-bottom: ${({ theme }) => theme.spacing(5)};
  }

  & h2 {
    ${({ theme }) => theme.fonts.h2};
    padding-top: ${({ theme }) => theme.spacing(3)};
    margin-bottom: ${({ theme }) => theme.spacing(5)};
  }

  & h3 {
    ${({ theme }) => theme.fonts.h3};
  }

  & h4 {
    ${({ theme }) => theme.fonts.h4};
  }

  & pre {
    padding: ${({ theme }) => theme.spacing(2)};
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    background: ${({ theme }) => darken(0.05, theme.colors.grey_25)};
    overflow-x: auto;
    line-height: normal;
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  }

  & pre code {
    font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono,
      Courier New, monospace, serif;
    ${({ theme }) => theme.fonts.caption};
  }

  & em {
    margin-bottom: ${({ theme }) => theme.spacing(4)};
    opacity: 0.7;
  }

  & blockquote {
    text-align: center;
    padding-top: ${({ theme }) => theme.spacing(2)};
    margin: ${({ theme }) => theme.spacing(0, 'auto', 7)};
    max-width: 650px;
    font-weight: 700;
    line-height: 36px;

    &::before {
      content: url('/icons/quote_sign.svg');
    }

    & p {
      opacity: 0.7;
      padding-top: ${({ theme }) => theme.spacing(1)};
    }
  }

  & dl {
    display: flex;
    flex-direction: column;
    margin-bottom: ${({ theme }) => theme.spacing(4)};

    ${mq.large`
        flex-direction: row;
        flex-wrap: wrap;
    `}
  }

  & dt {
    font-weight: 800;
    margin-bottom: ${({ theme }) => theme.spacing(1)};

    ${mq.large`
      flex: 0 0 20%;
      margin-bottom: ${({ theme }) => theme.spacing(4)};
    `}
  }

  & dd {
    margin-bottom: ${({ theme }) => theme.spacing(4)};
    font-weight: 300;

    ${mq.large`
      flex: 0 0 80%;
    `}
  }

  & iframe {
    margin: ${({ theme }) => theme.spacing(4, 0)};
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    overflow: hidden;
  }

  & img {
    display: block;
    max-width: 100%;
    margin: ${({ theme }) => theme.spacing(2, 'auto')};
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    overflow: hidden;
  }

  & a {
    text-decoration: underline;
    word-wrap: break-word;
  }

  & a:hover {
    opacity: 0.75;
  }

  & ul {
    list-style-type: none;
    padding: ${({ theme }) => theme.spacing(2, 0, 0, 4)};
    margin-bottom: ${({ theme }) => theme.spacing(2)};

    & li {
      padding: 0;
      margin: 0;
    }

    & li:before {
      content: '–';
      position: absolute;
      margin-left: ${({ theme }) => theme.spacing(-4)};
    }
  }

  & ol {
    list-style: outside decimal;
    padding-left: ${({ theme }) => theme.spacing(3)};
    margin-bottom: ${({ theme }) => theme.spacing(2)};

    & li {
      margin: 0;
      padding-left: ${({ theme }) => theme.spacing(2)};
    }

    & li::before {
      display: none;
    }
  }
`;

export const rhombusUnorderedList = css`
  & ul {
    margin-bottom: ${({ theme }) => theme.spacing(9)};
    padding: 0;

    & li {
      position: relative;
      max-width: 90%;
      padding-left: ${({ theme }) => theme.spacing(4)};
      margin-bottom: ${({ theme }) => theme.spacing(3)};

      ${mq.large`
        max-width: 100%;
        padding-left: ${({ theme }) => theme.spacing(5)};
        margin-bottom: ${({ theme }) => theme.spacing(3)};
      `}
    }

    & li::before {
      content: url('/icons/listIconMobile.svg');
      position: absolute;
      margin: 0;
      left: 0;

      ${mq.large`
        top: ${({ theme }) => theme.spacing(0.5)};
        content: url('/icons/listIcon.svg');
      `}
    }
  }
`;
