import styled from 'styled-components';

import fonts from '../../../../theme/fonts';

type TextAreaProps = {
  height?: string;
  margin?: string;
  variant?: string;
};

export const StyledWrapper = styled.div<TextAreaProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  margin: ${({ margin }) => margin || '0'};
`;

export const StyledLabel = styled.label<TextAreaProps>`
  ${fonts.body3};
  color: ${({ theme, variant }) => theme.contactFormVariant[variant].label.color};
`;

export const StyledOptionalLabel = styled(StyledLabel)`
  opacity: 0.5;
`;

export const StyledTextArea = styled.textarea<TextAreaProps>`
  ${({ theme, variant }) => theme.contactFormVariant[variant].input};
  position: relative;
  color: ${({ theme }) => theme.colors.icons};
  border-radius: 4px;
  border: 2px solid transparent;
  height: ${({ height }) => height || '40px'};
  padding: ${({ theme }) => theme.spacing(0, 2)};
  margin-top: ${({ theme }) => theme.spacing(1)};
  background-color: ${(props) => props.theme.colors.white};
  resize: none;
  outline: none;
  font-weight: 500;
  overflow: hidden;
  font-size: 14px;
  width: 100%;

  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.green_100};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.placeholderText};
    font-size: 14px;
    font-weight: 400;
    opacity: 0.5;
  }
`;
