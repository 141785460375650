import { css } from 'styled-components';

import { colorSchemes } from './color-schemes';

const fcs: typeof css = (first: any, ...interpolations: Array<any>) =>
  colorSchemes.map(
    (scheme) => css`
      @media (prefers-color-scheme: ${scheme}) {
        ${css(first, ...interpolations)}
      }
    `
  );

export default fcs;
