import React, { FC, useEffect } from 'react';
import Head from 'next/head';

import fromCMS from 'utils/fromCMS';

export type MetaTag = {
  id: string;
  property: string;
  content: string;
};

export type MetaTagsProps = {
  metaTags: Array<MetaTag>;
  ogImage?: {
    url: string;
  };
  data?: string;
  link?: {
    rel: string;
    href: string;
  };
};

const MetaTags: FC<MetaTagsProps> = ({ metaTags, ogImage, data, link }) => {
  const filteredMetaTags = ogImage ? metaTags.filter(({ property }) => property !== 'og:image') : metaTags;
  const [descriptionMetaTag] = metaTags.filter(({ property }) => property === 'description');

  useEffect(() => {
    document.getElementById('script-div') && document.getElementById('script-div').remove();

    if (data) {
      const element = document.createElement('div');
      element.id = 'script-div';
      document.body.appendChild(element).innerHTML = `<script type="application/ld+json">${JSON.stringify(
        data
      )}</script>`;
    }
  }, [data]);

  return (
    <Head>
      {filteredMetaTags
        .filter((item) => item.property !== 'description')
        .map(({ id, property, content }) => (
          <meta property={property} content={content} key={id} />
        ))}
      {ogImage && <meta property="og:image" content={fromCMS(ogImage.url)} />}
      {descriptionMetaTag && <meta name={descriptionMetaTag.property} content={descriptionMetaTag.content} />}
      {link && <link rel={link.rel} href={link.href} />}
    </Head>
  );
};

export default MetaTags;
