import styled from 'styled-components';

type ShapeContainerProps = {
  position: 'relative' | 'absolute';
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  zIndex?: number;
};

export const StyledShapeContainer = styled.div<ShapeContainerProps>`
  display: flex;
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  z-index: ${({ zIndex }) => zIndex};
  padding: 0;
  margin: 0;
`;
