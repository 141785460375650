const getBrowserParams = () => ({
  pathname: window?.location?.pathname
});

const args2String = (params): string => {
  const glue = ': ';
  const separator = ', ';

  return Object.keys(params)
    .map(function (key) {
      return [key, params[key]].join(glue);
    })
    .join(separator);
};

export const getBrowserInfo = (): string => args2String(getBrowserParams());
