import styled from 'styled-components';

import { mq } from 'theme/media';

export const StyledContainer = styled.section<{
  maxWidth?: string;
}>`
  width: 100%;
  padding: 0 ${({ theme }) => theme.sizes.layoutPadding};
  margin: 0 auto;
  position: relative;

  ${mq.small`
    max-width: ${({ theme }) => theme.sizes.container.small};
  `}

  ${mq.medium`
    max-width: ${({ theme }) => theme.sizes.container.medium};
  `}

  ${mq.large`
    max-width: ${({ theme, maxWidth }) => (maxWidth ? maxWidth : theme.sizes.container.large)};
  `}

  ${mq.xsLarge`
    max-width: ${({ theme, maxWidth }) => (maxWidth ? maxWidth : theme.sizes.container.xsLarge)};
    padding: 0;
  `}
`;

export const StyledWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing(4)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;
