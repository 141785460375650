import React, { ReactElement, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { AnimatePresence } from 'framer-motion';

import Typography from 'components/atoms/Typography/Typography';
import useClickOutside from 'hooks/useClickOutside';

import {
  StyledNavBarItem,
  StyledLanguageMenuContainer,
  StyledImageContainer,
  StyledLanguageMenu,
  StyledLanguageMenuItem,
  StyledLanguageSelectorArrow,
  StyledLanguageImageContainer
} from './LanguageSelector.styles';
import { getLocaleFullName } from './LanguageSelector.utils';

const LanguageSelector = ({ isMobile = false }): ReactElement => {
  const { locales, locale, asPath } = useRouter();

  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);

  const closeLanguageMenu = () => setIsLanguageMenuOpen(false);
  const toggleIsLanguageMenuOpen = () => setIsLanguageMenuOpen((previousState) => !previousState);

  const languageMenuRef = useClickOutside(closeLanguageMenu);

  const LanguageMenu = () => (
    <AnimatePresence>
      {isLanguageMenuOpen && (
        <StyledLanguageMenu isMobile={isMobile}>
          {locales
            .filter((loc) => loc !== locale)
            .map((locale) => (
              <StyledLanguageMenuItem key={locale}>
                <Link href={asPath} locale={locale}>
                  <StyledImageContainer>
                    <Image
                      src={`/icons/${locale}.svg`}
                      alt={`language icon - ${getLocaleFullName(locale)}`}
                      width="24px"
                      height="24px"
                    />
                    <Typography variant="caption">
                      {getLocaleFullName(locale)} ({locale.toUpperCase()})
                    </Typography>
                  </StyledImageContainer>
                </Link>
              </StyledLanguageMenuItem>
            ))}
        </StyledLanguageMenu>
      )}
    </AnimatePresence>
  );

  return (
    <StyledNavBarItem ref={languageMenuRef} isMenuOpen={isLanguageMenuOpen}>
      <StyledLanguageMenuContainer>
        <StyledLanguageImageContainer>
          <Image
            src={`/icons/${locale}.svg`}
            alt={`language icon - ${getLocaleFullName(locale)}`}
            width="24px"
            height="24px"
          />
          {isMobile && (
            <Typography variant="h5" weight="400">
              {getLocaleFullName(locale)} ({locale.toUpperCase()})
            </Typography>
          )}
        </StyledLanguageImageContainer>
        <StyledLanguageSelectorArrow onClick={toggleIsLanguageMenuOpen} isLanguageMenuOpen={isLanguageMenuOpen}>
          <Image src="/icons/vector.svg" alt="arrow icon" width="12px" height="7px" />
        </StyledLanguageSelectorArrow>
      </StyledLanguageMenuContainer>
      <LanguageMenu />
    </StyledNavBarItem>
  );
};

export default LanguageSelector;
