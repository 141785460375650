import { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { getPortalOutlet } from './Portal.utils';

export const Portal: FC = ({ children }) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted ? createPortal(children, getPortalOutlet()) : null;
};
