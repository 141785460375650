import styled, { css } from 'styled-components';

import { AppLink } from 'components/AppLink/AppLink';

import { mq } from 'theme/media';
import { FooterSpace } from 'utils/FooterSpaceContext';

export const StyledFooterContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const StyledBackgroundContainer = styled.div<{ spacing: FooterSpace['spacing'] }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-top: ${({ theme }) => theme.spacing(5)};
  padding-bottom: ${({ theme }) => theme.spacing(3)};
  background: ${({ theme }) => theme.colors.darkGray};
  color: ${({ theme }) => theme.colors.white};
  z-index: 0;

  ${({ spacing }) =>
    spacing > 0 &&
    css`
      margin-bottom: ${spacing}px;
    `}

  ${mq.large`
    clip-path: polygon(0 0, 100% 45px, 100% 100%, 0% 100%);
    padding-top: ${({ theme }) => theme.spacing(9)};
    padding-bottom: ${({ theme }) => theme.spacing(5)};
    margin-bottom: 0;
  `}
`;

export const StyledContent = styled.div`
  width: 100%;
  max-width: 1040px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  ${mq.xLarge`
    padding: 0;
  `}

  ${mq.large`
    align-items: stretch;
  `}
`;

export const StyledFooterBody = styled.div`
  display: grid;
  grid-template-areas: 'contact' 'services' 'company' 'socials' 'clutch';

  ${mq.large`
    width: 100%;
    grid-gap: ${({ theme }) => theme.spacing(1, 9)};
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      'contact services company socials'
      'contact services company socials'
      'headquarters location company clutch';
  `}
`;

export const StyledContactBox = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: contact;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  align-items: center;

  ${mq.large`
    padding-bottom: ${({ theme }) => theme.spacing(1)};
    margin-bottom: 0;
    align-items: stretch;
  `}
`;

export const StyledLogoBox = styled.div`
  flex-grow: 1;
  align-self: center;

  ${mq.large`
    align-self: auto;
  `}
`;

export const StyledLink = styled(AppLink)`
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;

  &:hover {
    opacity: 0.55;
  }

  ${mq.large`
    text-align: left;
  `}
`;

export const StyledLinkBlue = styled(StyledLink)`
  color: ${({ theme }) => theme.colors.light};
`;

export const StyledTitle = styled.div`
  opacity: 0.5;
  margin: ${({ theme }) => theme.spacing(2, 0, 1)};

  ${mq.large`
     margin: ${({ theme }) => theme.spacing(2, 0, 0.5)};
  `}
`;

export const StyledItemWrapper = styled.span`
  opacity: 0.8;
`;

export const StyledAddressTitleWrapper = styled(StyledTitle)`
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

export const StyledLinksTitle = styled(StyledTitle)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  ${mq.large`
    margin-bottom: ${({ theme }) => theme.spacing(3)};
  `}
`;

export const StyledLinksBox = styled.div<{ name: string }>`
  grid-area: ${({ name }) => name};
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq.large`
    margin-top: ${({ theme }) => theme.spacing(5)};
    align-items: flex-start;
  `}
`;

export const StyledClutchBox = styled.div<{ name: string }>`
  grid-area: ${({ name }) => name};
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq.large`
    align-items: flex-start;
  `}
`;

export const StyledList = styled.ul`
  list-style-type: none;
`;

export const StyledListItem = styled.li<{ centered: boolean }>`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  display: ${({ centered }) => (centered ? 'flex' : 'list-item')};
  justify-content: center;

  ${mq.large`
    display: list-item;
  `}
`;

export const StyledFooterBox = styled.address<{ name: string }>`
  grid-area: ${({ name }) => name};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  align-self: center;
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

export const StyledFooterLine = styled.hr`
  height: 1px;
  width: 100%;
  border: none;
  border-top: 1px solid ${({ theme }) => theme.colors.green_100};
  margin: ${({ theme }) => theme.spacing(4, 0, 2)};

  ${mq.large`
    margin: ${({ theme }) => theme.spacing(6, 0, 3)};
  `}
`;

export const StyledFooterCopyright = styled.small`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing(1)};
  opacity: 0.5;
  text-align: center;

  ${mq.large`
    display: flex;
    flex-direction: column;
    flex-direction: row;
    justify-content: space-between;
  `}
`;
