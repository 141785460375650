import React, { FC, Fragment, useContext } from 'react';
import Image from '@next/image';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote/dist/types';

import { AppLink } from 'components/AppLink/AppLink';
import Container from 'components/atoms/Container/Container';
import Typography from 'components/atoms/Typography/Typography';
import { Marked } from 'components/Marked/Marked';
import { PulsingHeart } from 'components/PulsingHeart/PulsingHeart';

import useMedia from 'hooks/useMedia';

import { FooterSpaceContext } from 'utils/FooterSpaceContext';
import fromCMS from 'utils/fromCMS';

import { Shape } from 'components/atoms/Shape/Shape';
import { ClutchProps } from 'utils/types/Clutch';
import ClutchWidget from 'components/atoms/ClutchWidget/ClutchWidget';
import {
  StyledBackgroundContainer,
  StyledContactBox,
  StyledContent,
  StyledFooterBody,
  StyledLink,
  StyledLogoBox,
  StyledTitle,
  StyledLinksBox,
  StyledIconWrapper,
  StyledFooterLine,
  StyledFooterCopyright,
  StyledFooterBox,
  StyledListItem,
  StyledList,
  StyledAddressTitleWrapper,
  StyledItemWrapper,
  StyledLinksTitle,
  StyledClutchBox,
  StyledFooterContainer
} from './Footer.styles';

export type FooterProps = {
  logo?: {
    image: {
      url: string;
      alternativeText: string;
    };
    path: string;
  };
  contact?: Array<{
    title: string;
    link?: {
      name: string;
      path: string;
    };
  }>;
  linkGroups?: Array<{
    linkGroupId: string;
    title: string;
    links: Array<{
      name: string;
      path?: string;
      icon?: { url: string; alternativeText: string };
    }>;
  }>;
  addresses?: Array<{
    addressId: string;
    path?: string;
    data: {
      name: string;
      city: string;
      code: string;
      address: MDXRemoteSerializeResult;
      country: string;
      fullAddress: MDXRemoteSerializeResult;
    };
  }>;
  clutch: ClutchProps;
};

export const CENTERED_LINKS = ['services', 'company'];

export const Footer: FC<FooterProps> = ({ logo, contact, linkGroups, addresses, clutch }) => {
  const { isMobile, isTinyDesktop } = useMedia();
  const { spacing } = useContext(FooterSpaceContext);

  return (
    <StyledFooterContainer>
      {!isTinyDesktop && !isMobile && (
        <Shape
          src="/shapes/form_right_down_corner.png"
          width={224}
          height={232}
          position="absolute"
          right="40px"
          top="-60px"
          zIndex={1}
        />
      )}
      <StyledBackgroundContainer spacing={spacing}>
        <Container renderAs="footer">
          <StyledContent>
            <StyledFooterBody>
              <StyledContactBox>
                <StyledLogoBox>
                  <StyledLink href={logo.path}>
                    <Image
                      width={51}
                      height={58}
                      src={fromCMS(logo.image.url)}
                      alt={logo.image.alternativeText}
                      quality={100}
                      hasFade={false}
                    />
                  </StyledLink>
                </StyledLogoBox>
                {contact.map(({ title, link }) => (
                  <Fragment key={title}>
                    <StyledTitle>
                      <Typography variant="body2" weight="300">
                        {title}
                      </Typography>
                    </StyledTitle>
                    {link && (
                      <StyledLink href={link.path}>
                        <StyledItemWrapper>
                          <Typography variant="body2" weight="700">
                            {link.name}
                          </Typography>
                        </StyledItemWrapper>
                      </StyledLink>
                    )}
                  </Fragment>
                ))}
              </StyledContactBox>
              {linkGroups.map(({ linkGroupId, title, links }) => (
                <StyledLinksBox key={linkGroupId} name={linkGroupId}>
                  <StyledLinksTitle>{title}</StyledLinksTitle>
                  <StyledList>
                    {links.map(({ name, path, icon }) => (
                      <StyledListItem key={name} centered={CENTERED_LINKS.includes(linkGroupId)}>
                        <StyledLink href={path}>
                          {icon && (
                            <StyledIconWrapper>
                              <Image
                                src={fromCMS(icon.url)}
                                alt={icon.alternativeText}
                                objectFit="contain"
                                width="16px"
                                height="16px"
                                hasFade={false}
                              />
                            </StyledIconWrapper>
                          )}
                          <Typography variant="body2">{name}</Typography>
                        </StyledLink>
                      </StyledListItem>
                    ))}
                  </StyledList>
                </StyledLinksBox>
              ))}
              {!isMobile && (
                <>
                  {addresses.map(({ addressId, path, data }) => (
                    <StyledFooterBox key={addressId} name={addressId}>
                      <StyledAddressTitleWrapper>
                        <Typography variant="subtitle">{data.name}</Typography>
                      </StyledAddressTitleWrapper>
                      <>
                        {path ? (
                          <StyledLink href={path}>
                            <Typography variant="caption" weight="300">
                              <Marked>{data.address}</Marked> <Marked>{data.fullAddress}</Marked>
                            </Typography>
                          </StyledLink>
                        ) : (
                          <Typography variant="caption" weight="300">
                            <Marked>{data.address}</Marked> <Marked>{data.fullAddress}</Marked>
                          </Typography>
                        )}
                      </>
                    </StyledFooterBox>
                  ))}
                </>
              )}
              <StyledClutchBox name="clutch">
                <ClutchWidget {...clutch} option="square" />
              </StyledClutchBox>
            </StyledFooterBody>
            <StyledFooterLine />
            <StyledFooterCopyright>
              <Typography variant="body2" weight="300">
                &copy; {new Date().getFullYear()} <AppLink href="https://likims.com">Likims.com</AppLink>. All rights
                reserved
              </Typography>
              <Typography variant="body2" weight="300">
                Made with <PulsingHeart /> in Poland
              </Typography>
            </StyledFooterCopyright>
          </StyledContent>
        </Container>
      </StyledBackgroundContainer>
    </StyledFooterContainer>
  );
};
