import { keyframes } from 'styled-components';

import { transition } from './transition';

export const scaleUp = {
  hidden: {
    scale: 0.41
  },
  visible: {
    scale: 1,
    transition: transition.medium
  }
};

export const revealLeft = {
  hidden: {
    x: '-100%'
  },
  visible: {
    x: 0,
    transition: transition.medium
  }
};

export const revealRight = {
  hidden: {
    x: '100%'
  },
  visible: {
    x: 0,
    transition: transition.medium
  }
};

export const revealRightFast = {
  hidden: {
    x: '100%',
    transition: transition.acceleratedFast
  },
  visible: {
    x: 0,
    transition: transition.acceleratedFast
  }
};

export const fadeIn = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: transition.slowWithNormalDelay
  }
};

export const fadeInFast = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: transition.fast
  }
};

export const fadeInLeft = {
  hidden: {
    opacity: 0,
    x: '-100%'
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: transition.normal
  }
};

export const fadeInRight = {
  hidden: {
    opacity: 0,
    x: '100%'
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: transition.normal
  }
};

export const fadeInUp = {
  hidden: {
    opacity: 0,
    y: '-100px'
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: transition.normal
  }
};

export const fadeInOut = {
  hidden: {
    opacity: 0,
    transition: transition.fast
  },
  visible: {
    opacity: 1,
    transition: transition.fast
  }
};

export const revealBottomFast = {
  hidden: {
    height: 0,
    transition: transition.superFast
  },
  visible: {
    height: 'auto',
    transition: transition.fast
  }
};

export const revealBottomRightFast = {
  hidden: {
    y: '100%',
    x: '100%',
    opacity: 0,
    transition: transition.acceleratedFast
  },
  visible: {
    y: 0,
    x: 0,
    opacity: 1,
    transition: transition.acceleratedFast
  }
};

export const fadeInBottom = {
  hidden: {
    opacity: 0,
    y: '100px'
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: transition.fast
  }
};

export const fadeInBottomFast = {
  hidden: {
    opacity: 0,
    y: '50px'
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: transition.fast
  }
};

export const fadeInTopIcons = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: transition.superFast
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: transition.superFast
  }
};

export const fadeInWithChildren = {
  hidden: {
    opacity: 0,
    transition: {
      staggerChildren: 0.125,
      when: 'afterChildren'
    }
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.125
    }
  }
};

export const fadeInWithHiddenChildren = {
  hidden: {
    opacity: 0,
    transition: {
      staggerChildren: 0.06,
      delay: 0.2
    },
    transitionEnd: {
      display: 'none'
    }
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.06
    },
    display: 'flex'
  }
};

export const fadeInWithChildrenReverse = {
  visible: {
    transition: {
      staggerChildren: 0.25,
      ...transition.fast
    },
    opacity: 1
  },
  hidden: {
    transition: {
      staggerChildren: 0.25,
      staggerDirection: -1,
      ...transition.fast
    },
    opacity: 0
  }
};

export const slowScaling = {
  visible: {
    scale: 1.075,
    transition: {
      repeat: Infinity,
      repeatType: 'reverse',
      ...transition.slow
    }
  },
  hidden: { scale: 0.925 }
};

export const arrowAnimationDown = keyframes`
  0% {
    transform: translateY(0) rotate(90deg);
  }
  25% {
    transform: translateY(2px) rotate(90deg);
  }
  50% {
    transform: translateY(0) rotate(90deg);
  }
  75% {
    transform: translateY(4px) rotate(90deg);
  }
`;

export const arrowAnimationRight = keyframes`
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(3px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(6px);
  }
`;

export const arrowAnimationLeft = keyframes`
  0% {
    transform: translateX(0) rotate(180deg);
  }
  25% {
    transform: translateX(-3px) rotate(180deg);
  }
  50% {
    transform: translateX(0) rotate(180deg);
  }
  75% {
    transform: translateX(-6px) rotate(180deg);
  }
`;

export const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.25);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.25);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
`;
