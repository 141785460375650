import React, { ComponentType, FC } from 'react';
import dynamic from 'next/dynamic';

const Adobe = dynamic(() => import('../Technologies/Adobe'));
const AdobeAi = dynamic(() => import('../Technologies/AdobeAi'));
const AdobeXd = dynamic(() => import('../Technologies/AdobeXd'));
const Android = dynamic(() => import('../Technologies/Android'));
const Angular = dynamic(() => import('../Technologies/Angular'));
const CSS = dynamic(() => import('../Technologies/CSS'));
const Figma = dynamic(() => import('../Technologies/Figma'));
const Html = dynamic(() => import('../Technologies/Html'));
const InLogo = dynamic(() => import('../Technologies/InLogo'));
const Ios = dynamic(() => import('../Technologies/Ios'));
const Javascript = dynamic(() => import('../Technologies/Javascript'));
const Node = dynamic(() => import('../Technologies/Node'));
const Photoshop = dynamic(() => import('../Technologies/Photoshop'));
const Python = dynamic(() => import('../Technologies/Python'));
const ReactComponent = dynamic(() => import('../Technologies/React'));
const RubyOnRails = dynamic(() => import('../Technologies/RubyOnRails'));
const Sketch = dynamic(() => import('../Technologies/Sketch'));
const Zeplin = dynamic(() => import('../Technologies/Zeplin'));
const Design = dynamic(() => import('../Technologies/Design'));
const Devops = dynamic(() => import('../Technologies/Devops'));
const Facebook = dynamic(() => import('../Socials/Facebook'));
const LinkedIn = dynamic(() => import('../Socials/LinkedIn'));
const Twitter = dynamic(() => import('../Socials/Twitter'));
const Dimension = dynamic(() => import('../Technologies/Dimension'));
const Firebase = dynamic(() => import('../Technologies/Firebase'));
const Loader = dynamic(() => import('../Widget/Loader'));
const Button = dynamic(() => import('../Widget/Button'));

export type Icons =
  | 'adobe'
  | 'adobeAi'
  | 'adobeXd'
  | 'android'
  | 'angular'
  | 'css'
  | 'design'
  | 'devops'
  | 'dimension'
  | 'facebook'
  | 'figma'
  | 'firebase'
  | 'html'
  | 'inLogo'
  | 'ios'
  | 'javascript'
  | 'linkedin'
  | 'node'
  | 'photoshop'
  | 'python'
  | 'react'
  | 'rubyOnRails'
  | 'sketch'
  | 'twitter'
  | 'zeplin'
  | 'loader'
  | 'button';

export type IconResolverProps = {
  id: Icons;
  color?: string;
};

const componentsMap: { [key in Icons]: ComponentType } = {
  adobe: Adobe,
  adobeAi: AdobeAi,
  adobeXd: AdobeXd,
  android: Android,
  angular: Angular,
  css: CSS,
  design: Design,
  devops: Devops,
  dimension: Dimension,
  facebook: Facebook,
  figma: Figma,
  firebase: Firebase,
  html: Html,
  inLogo: InLogo,
  ios: Ios,
  javascript: Javascript,
  linkedin: LinkedIn,
  node: Node,
  photoshop: Photoshop,
  python: Python,
  react: ReactComponent,
  rubyOnRails: RubyOnRails,
  sketch: Sketch,
  twitter: Twitter,
  zeplin: Zeplin,
  loader: Loader,
  button: Button
};

const IconResolver: FC<IconResolverProps> = ({ id, ...props }) => {
  const Component = componentsMap[id];

  return <Component {...props} />;
};

export default IconResolver;
