export const translations = {
  en: {
    'contact-form.optional': '(optional)',
    'contact-form.name.label': 'Name',
    'contact-form.name.placeholder': 'Personal or company name',
    'contact-form.email.label': 'Company email address',
    'contact-form.email.placeholder': 'youremail@address.here',
    'contact-form.phone.label': 'Phone',
    'contact-form.phone.placeholder': 'Contact phone number',
    'contact-form.message.label': 'Message',
    'contact-form.message.placeholder': 'Project details',
    'contact-form.legal-notice': 'By clicking Send, you agree to our Terms & Conditions.',
    'contact-form.send': 'Send',
    'contact-form.success': 'Thank you, your message has been sent.',
    'contact-form.error': 'Sorry, an error has occured.',
    'contact-form.error-action': 'Please try again later or contact us and report the problem.',
    'contact-form.error-contact': 'Send info to admins:',
    'cookies-banner-info': 'We use cookies in order to personalize site experience.',
    'cookies-banner-allow': 'Allow',
    'cookies-banner-decline': 'Decline'
  },
  de: {
    'contact-form.optional': '(Optional)',
    'contact-form.name.label': 'Name',
    'contact-form.name.placeholder': 'Personen- oder Firmenname',
    'contact-form.email.label': 'E-Mail-Adresse des Unternehmens',
    'contact-form.email.placeholder': 'ihreemail@adresse.hier',
    'contact-form.phone.label': 'Telefonnummer',
    'contact-form.phone.placeholder': 'Kontakt Telefonnummer',
    'contact-form.message.label': 'Nachricht',
    'contact-form.message.placeholder': 'Projekt Details',
    'contact-form.legal-notice':
      'Indem Sie auf Senden klicken, stimmen Sie unseren Allgemeinen Geschäftsbedingungen zu.',
    'contact-form.send': 'Senden',
    'contact-form.success': 'Vielen Dank! Ihre Nachricht wurde gesendet.',
    'contact-form.error': 'Entschuldigung, es ist ein Fehler aufgetreten.',
    'contact-form.error-action':
      'Bitte versuchen Sie es später erneut oder kontaktieren Sie uns und melden Sie uns das Problem.',
    'contact-form.error-contact': 'Info senden:',
    'cookies-banner-info': 'Wir verwenden Cookies, um Website-Erfahrung zu personalisieren.',
    'cookies-banner-allow': 'Akzeptieren',
    'cookies-banner-decline': 'Ablehnen'
  },
  pl: {
    'contact-form.optional': '(opcjonalnie)',
    'contact-form.name.label': 'Imię',
    'contact-form.name.placeholder': 'Imię lub nazwa firmy',
    'contact-form.email.label': 'Służbowy adres email',
    'contact-form.email.placeholder': 'youremail@address.here',
    'contact-form.phone.label': 'Telefon',
    'contact-form.phone.placeholder': 'Kontaktowy numer telefonu',
    'contact-form.message.label': 'Wiadomość',
    'contact-form.message.placeholder': 'Szczegóły projektu',
    'contact-form.legal-notice': 'Klikając wyślij, wyrażasz zgodę na nasz Regulamin',
    'contact-form.send': 'Wyślij',
    'contact-form.success': 'Dziękujemy, twoja wiadomość została wysłana',
    'contact-form.error': 'Przepraszamy, ale wystąpił błąd.',
    'contact-form.error-action': 'Prosimy spróbować ponownie później lub zgłosić nam problem.',
    'contact-form.error-contact': 'Wyślij zgłoszenie:',
    'cookies-banner-info': 'Używamy plików cookie, aby spersonalizować naszą stronę internetową.',
    'cookies-banner-allow': 'Zaakceptuj',
    'cookies-banner-decline': 'Odrzuć'
  }
};
