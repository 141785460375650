import { createGlobalStyle } from 'styled-components';
import { reset } from 'styled-reset';
import fcs from 'theme/force-color-scheme';
import { mq } from 'theme/media';

import theme from './theme';

export const GlobalStyle = createGlobalStyle`
  ${reset};
  
  *, *:before, *:after {
    box-sizing: border-box;
  } 

  strong {
    font-weight: bold;
  }


  body {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin: 0;
    font-size: 14px;
    line-height: normal;
    overflow-x: hidden;
    ${fcs`
      background-color: ${theme.colors.white};
      color: ${theme.colors.green_400};
    `}
  } 

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    font-family: inherit;
    line-height: 0
  }
  
  input, textarea {
    font-family: inherit;
  }

  #__next {
    box-sizing: border-box;
    margin-top: 70px;

    ${mq.xsLarge`
      margin-top: 74px;
    `}
  }
`;
