import React, { FC } from 'react';

import { GenericButton, GenericProps } from '../GenericButton';

export const FuncButton: FC<GenericProps> = ({
  children,
  margin,
  variant = 'primary',
  width = '208px',
  height = '56px',
  disabled,
  onClick
}) => (
  <GenericButton
    variant={variant}
    margin={margin}
    width={width}
    height={height}
    onClick={onClick}
    disabled={disabled}
    as="button"
  >
    {children}
  </GenericButton>
);
