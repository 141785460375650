const PHONE_VALIDATION_REGEX = /^[+]*[0-9]*$/;
const EMAIL_VALIDATION_REGEX =
  /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const TRANSCRIPTIONS_REGEX = /START_TRANSCRIPTION(.|\n)*?END_TRANSCRIPTION/;
const TRANSCRIPTIONS_EXCLUDE_REGEX = /(?:START_TRANSCRIPTION)(.|\n)*?(?=END_TRANSCRIPTION)/g;
const SINGLE_TRANSCRIPTION_REGEX = /.*:(.|\n)*?(?=\n.*:)/g;

export default {
  EMAIL_VALIDATION_REGEX,
  TRANSCRIPTIONS_EXCLUDE_REGEX,
  TRANSCRIPTIONS_REGEX,
  SINGLE_TRANSCRIPTION_REGEX,
  PHONE_VALIDATION_REGEX
};
