export const transition = {
  superFast: {
    duration: 0.25
  },
  acceleratedFast: {
    duration: 0.38
  },
  fast: {
    duration: 0.5
  },
  medium: {
    duration: 0.8
  },
  normal: {
    duration: 1
  },
  slow: {
    duration: 1.5
  },
  slowWithNormalDelay: {
    duration: 1.5,
    delay: 1
  }
};
