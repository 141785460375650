import { createContext, useCallback, useContext } from 'react';
import { useRouter } from 'next/router';

import { DEFAULT_LOCALE } from '../locale';
import { translations } from './translations';
import { TranslateFunction, TranslationKey, TranslationsConfig } from './types';

export const TranslationsConfigContext = createContext<TranslationsConfig>(translations);

export const TranslationsConfigProvider = TranslationsConfigContext.Provider;

export const useTranslationsConfig = (): TranslationsConfig => useContext(TranslationsConfigContext);

export const useTranslations = (): TranslateFunction => {
  const { locale = DEFAULT_LOCALE } = useRouter();
  const translationsConfig = useTranslationsConfig();

  const t = useCallback<TranslateFunction>(
    (translationKey: TranslationKey, fallback?: string) => translationsConfig[locale]?.[translationKey] ?? fallback,
    [translationsConfig, locale]
  );

  return t;
};
