import routes from './routes';

const { ROUTES } = routes;

enum KnowledgeTypes {
  Blog,
  CaseStudy
}

const KNOWLEDGE = {
  FILTERS: [
    {
      ...ROUTES.BLOG,
      id: KnowledgeTypes.Blog
    },
    {
      ...ROUTES.CASE_STUDY,
      id: KnowledgeTypes.CaseStudy
    }
  ],
  CASE_STUDY_ID: KnowledgeTypes.CaseStudy,
  BLOG_ID: KnowledgeTypes.Blog
};

export default { KNOWLEDGE };
