import React, { FC } from 'react';
import { Comment } from 'components/atoms/Comment/Comment';

import { fadeInFast } from 'theme/animations';
import fromCMS from 'utils/fromCMS';

import { WidgetPopupSpan, AnimatedWidgetButtonPopup } from './PopupWidget.styles';

export type PopupWidgetProps = {
  popupName: string;
  popupComment: string;
  popupImage: {
    alternativeText: string;
    url: string;
  };
};

export const PopupWidget: FC<PopupWidgetProps> = ({ popupName, popupComment, popupImage }) => (
  <AnimatedWidgetButtonPopup initial="hidden" animate="visible" exit="hidden" variants={fadeInFast}>
    <WidgetPopupSpan />
    <Comment
      name={popupName}
      comment={popupComment}
      image={fromCMS(popupImage.url)}
      imageAlt={popupImage.alternativeText}
    />
  </AnimatedWidgetButtonPopup>
);

export default PopupWidget;
