import { DocumentNode, gql } from '@apollo/client';
import { DEFAULT_LOCALE } from 'utils/locale';

export const contactFormQuery = (locale = DEFAULT_LOCALE): DocumentNode =>
  gql`
    {
      contactForm (locale: "${locale}") {
        title
        description
        contactFormInputs {
          label
          placeholder
          optional
        }
        buttonText
      }
    }
  `;

export const saveNewContactForm = (name: string, email: string, message: string, phone: string, metadata: string) =>
  gql`
    mutation {
      createNewContactForm(
        input: {
          data: {
            name: "${name}"
            email: "${email}"
            message: """${message}"""
            phone: "${phone}"
            accept: true
            metadata: "${metadata}"
          }
        }
      ) {
        newContactForm {
          id
        }
      }
    }
  `;
