import React, { FC, ElementType, ReactNode } from 'react';

import { StyledContainer } from './Container.styles';

type ContainerProps = {
  id?: string;
  children: ReactNode;
  renderAs?: ElementType;
  maxWidth?: string;
};

const Container: FC<ContainerProps> = ({ renderAs, children, id, maxWidth }) => (
  <StyledContainer id={id} as={renderAs} maxWidth={maxWidth}>
    {children}
  </StyledContainer>
);

export default Container;
