import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { useRouter } from 'next/router';

import { LinkedButton } from 'components/atoms/Button/LinkButton/LinkButton';
import { partition } from 'utils/partition';
import { isDesktopVisible, isMenuLink, MenuItem } from 'utils/types/Menu';

import { StyledButtonWrapper, StyledNavBarItems } from '../../Header.styles';
import LanguageSelector from '../../LanguageSelector/LanguageSelector';
import HeadMenu from './HeadMenu/HeadMenu';

type DesktopMenuProps = {
  menuItems: Array<MenuItem>;
  submenuItemsByParentId: Record<string, Array<MenuItem>>;
  setOpenedSubmenuId: Dispatch<SetStateAction<string | null>>;
};

const DesktopMenu = ({ menuItems, submenuItemsByParentId, setOpenedSubmenuId }: DesktopMenuProps): ReactElement => {
  const { locale } = useRouter();
  const [links, buttons] = partition(menuItems.filter(isDesktopVisible), isMenuLink);

  const handleDesktopMenuMouseOver = () => setOpenedSubmenuId(null);

  const handleHeadMenuMouseOver = (id: string) =>
    setOpenedSubmenuId(submenuItemsByParentId.hasOwnProperty(id) ? id : null);

  return (
    <StyledNavBarItems>
      {links.map(({ id, href, text }) => (
        <HeadMenu
          key={`desktop-head-menu-${id}`}
          id={id}
          href={href}
          text={text}
          mouseOverHandler={handleHeadMenuMouseOver}
        />
      ))}
      <LanguageSelector key={locale} />
      {buttons.map(({ id, href, text }) => (
        <StyledButtonWrapper key={`desktop-menu-button-${id}`} onMouseOver={handleDesktopMenuMouseOver}>
          <LinkedButton margin="0 0 0 18px" width="172px" height="40px" href={href}>
            {text}
          </LinkedButton>
        </StyledButtonWrapper>
      ))}
    </StyledNavBarItems>
  );
};

export default DesktopMenu;
