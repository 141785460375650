import styled from 'styled-components';
import { mq } from 'theme/media';

export const StyledClutchContainer = styled.div`
  display: flex;
  column-gap: 12px;
  align-items: center;
  margin-top: 16px;

  ${mq.large`
    margin-top:0;
  `}
`;

export const StyledDiv = styled.div`
  width: 214px;
  height: 50px;
  background-color: transparent;
  margin: 12px auto;

  ${mq.large`
    margin-top:0;
  `}
`;

export const StyledClutch = styled.div`
  width: 214px;
  height: 50px;
  border-radius: 0 25px 0 25px;
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  overflow: hidden;
  &:hover p {
    text-decoration: underline;
  }
`;

export const ReviewsText = styled.div`
  margin: 0;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.black};

  span {
    font-weight: 600;
    margin: 0;
  }
`;

export const StyledIcon = styled.div`
  background-color: ${({ theme }) => theme.colors.clutchBackground};
  padding: 10px 10px 10px 7px;
  width: 51px;
  display: flex;
  justify-content: center;
  border-top-right-radius: 25px;
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1px 5px 1px 10px;
`;

export const TopLine = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 8px;
  align-items: center;
`;

export const StarsWrapper = styled.div`
  display: flex;
  column-gap: 2px;
`;

export const StyledLink = styled.p`
  width: 100%;
  font-size: 8px;
`;

export const StyledClutchSquare = styled.div`
  display: flex;
  flex-direction: column;
  width: 90px;
  height: 70px;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 15px;
  overflow: hidden;
  padding-top: 8px;

  &:hover > p {
    text-decoration: underline;
  }
`;

export const RedText = styled.p`
  color: ${({ theme }) => theme.colors.clutch};
  font-weight: 300;
  margin: 0;
  font-size: 10px;
  letter-spacing: 1.5px;
`;

export const BottomBadge = styled.div`
  background-color: ${({ theme }) => theme.colors.clutchBackground};
  color: ${({ theme }) => theme.colors.white};
  font-size: 7px;
  text-align: center;
  width: 100%;
  padding: 6px 0;
`;
