import React, { FC, useState, useEffect, useContext } from 'react';
import { Router } from 'next/router';

import { AppLink } from 'components/AppLink/AppLink';
import Container from 'components/atoms/Container/Container';
import { Logo } from 'components/atoms/Logo/Logo';
import useMedia, { useMinWidthQuery } from 'hooks/useMedia';
import { HeaderContext } from 'utils/HeaderThemeContext';
import { groupBy } from 'utils/groupBy';
import { partition } from 'utils/partition';
import { MenuStructure } from 'utils/types/Menu';
import { LogoProps } from 'utils/types/Logo';

import { StyledHeader, StyledHeaderContent, StyledHeaderBox } from './Header.styles';
import MobileHamburger from './MobileHamburger/MobileHamburger';
import DesktopMenu from './Menus/DesktopMenu/DesktopMenu';
import DesktopSubMenu from './Menus/DesktopSubmenu/DesktopSubmenu';
import MobileMenu from './Menus/MobileMenu/MobileMenu';

export type HeaderProps = {
  menuStructure: MenuStructure;
  logo: LogoProps;
};

export const Header: FC<HeaderProps> = ({ menuStructure = [], logo }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openedSubmenuId, setOpenedSubmenuId] = useState<string | null>(null);
  const isDesktop = useMinWidthQuery('xsLarge');
  const { isMobile } = useMedia();
  const { theme: colorSchemeVariants } = useContext(HeaderContext);
  const currentColorScheme = isDesktop ? colorSchemeVariants.desktop : colorSchemeVariants.mobile;

  const [submenuItems, menuItems] = partition(menuStructure, (item) => typeof item.menu?.parentId === 'string');
  const submenuItemsByParentId = groupBy(submenuItems, (item) => item.menu.parentId);

  useEffect(() => {
    if (!isDesktop) {
      document.body.style.overflow = isOpen ? 'hidden' : null;

      return () => {
        document.body.style.overflow = null;
      };
    }
  }, [isOpen, isDesktop]);

  useEffect(() => {
    const handleRouteChange = () => {
      setIsOpen(false);
    };

    Router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return (
    <StyledHeaderBox id="header">
      <StyledHeader colorScheme={isOpen ? 'dark' : currentColorScheme}>
        <Container>
          <StyledHeaderContent>
            <AppLink href={logo.link}>
              <Logo url={logo.img.url} alternativeText={logo.img.alternativeText} />
            </AppLink>
            <nav>
              {isMobile ? (
                <MobileHamburger isOpen={isOpen} setIsOpen={setIsOpen} />
              ) : (
                <DesktopMenu
                  menuItems={menuItems}
                  submenuItemsByParentId={submenuItemsByParentId}
                  setOpenedSubmenuId={setOpenedSubmenuId}
                />
              )}
            </nav>
          </StyledHeaderContent>
        </Container>

        {!isMobile && (
          <DesktopSubMenu
            menuItems={menuItems}
            submenuItemsByParentId={submenuItemsByParentId}
            openSubmenuId={openedSubmenuId}
            setOpenedSubmenuId={setOpenedSubmenuId}
          />
        )}
      </StyledHeader>
      {isMobile && <MobileMenu isOpen={isOpen} menuItems={menuItems} submenuItemsByParentId={submenuItemsByParentId} />}
    </StyledHeaderBox>
  );
};
