import React, { FC } from 'react';
import Link from 'next/link';

import { isUrlExternal } from 'utils/isUrlExternal';
import { getFullUrl } from 'utils/getFullUrl';
import { DEFAULT_LOCALE } from 'utils/locale';

export type AppLinkProps = {
  href: string;
  passHref?: boolean;
  className?: any;
  title?: string;
  onMouseOver?: () => void;
};

export const AppLink: FC<AppLinkProps> = ({ href, passHref = true, className, children, title = '', onMouseOver }) => (
  <Link href={getFullUrl(href)} passHref={passHref} locale={DEFAULT_LOCALE}>
    <a
      className={className}
      target={isUrlExternal(href) ? '_blank' : null}
      rel="noopener noreferrer"
      title={title}
      onMouseOver={onMouseOver}
    >
      {children}
    </a>
  </Link>
);
