import React, { Dispatch, ReactElement, SetStateAction, useContext } from 'react';
import dynamic from 'next/dynamic';

import { useMinWidthQuery } from 'hooks/useMedia';
import { HeaderContext } from 'utils/HeaderThemeContext';
import { MenuItem } from 'utils/types/Menu';

const DropdownMenu = dynamic(() => import('../DropdownMenu/DropdownMenu'));

type DesktopSubmenuProps = {
  menuItems: Array<MenuItem>;
  submenuItemsByParentId: Record<string, Array<MenuItem>>;
  openSubmenuId: string | null;
  setOpenedSubmenuId: Dispatch<SetStateAction<string | null>>;
};

const DesktopSubMenu = ({
  menuItems,
  openSubmenuId,
  submenuItemsByParentId,
  setOpenedSubmenuId
}: DesktopSubmenuProps): ReactElement => {
  const isDesktop = useMinWidthQuery('xsLarge');
  const { theme: colorSchemeVariants } = useContext(HeaderContext);
  const currentColorScheme = isDesktop ? colorSchemeVariants.desktop : colorSchemeVariants.mobile;

  const handleDesktopSubmenuMouseOver = () => setOpenedSubmenuId(null);

  return (
    <>
      {menuItems
        .filter(({ id }) => submenuItemsByParentId.hasOwnProperty(id))
        .map((menuItem) => (
          <DropdownMenu
            key={`desktop-submenu-item-${menuItem.id}`}
            colorScheme={currentColorScheme}
            isVisible={openSubmenuId === menuItem.id}
            menuItem={menuItem}
            submenuItems={submenuItemsByParentId[menuItem.id]}
            onMouseLeave={handleDesktopSubmenuMouseOver}
          />
        ))}
    </>
  );
};

export default DesktopSubMenu;
