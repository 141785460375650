import ROUTES from './routes';
import REGEXP from './regexp';
import KNOWLEDGE from './knowledge';

export default {
  ...ROUTES,
  ...REGEXP,
  ...KNOWLEDGE,
  COOKIES_ACCEPTED_KEY: 'cookiesAccepted',
  DEFAULT_PAGE_SIZE: 12
};
