import fonts from './fonts';
import spacing from './spacing';

type Gradient = (firstColor: string, secondColor: string) => string;

const gradientsArray: Array<Gradient> = [
  (first, second) => `linear-gradient(90deg, ${first} 0%, ${second} 100%)`,
  (first, second) => `linear-gradient(270deg, ${first} 0%, ${second} 100%)`,
  (first, second) => `linear-gradient(215deg, ${first} 20%, ${second} 90%)`,
  (first, second) => `linear-gradient(240deg, ${first} 0%, ${second} 96%)`,
  (first, second) => `linear-gradient(79.28deg, ${first} 11.06%, ${second} 99.95%)`
];

const filters = {
  icon: 'grayscale(90%) opacity(50%)'
};

const colors = {
  backgroundMedium: '#eeebf5',
  buttonGreen: '#0E6138',
  candlelight: '#ffd110',
  carouselImagesShadow: '10px 10px 20px 5px rgba(0, 0, 0, 0.15)',
  clutchBackground: '#17313b',
  dark: '#258767',
  darkGray: '#1E2A3D',
  darkViolet: '#6E2AFF',
  icons: '#236430',
  light: '#299253',
  lighter: '#cffc9d',
  lightGray: '#DBE0EE',
  medium: '#5ba509',
  mediumBlue: '#2B3545',
  persimmon: '#ff5a5a',
  placeholderText: '#B4B6BA',
  primaryBlack: '#333333',
  primaryGray: '#B2B9C4',
  secondaryGray: '#838FAC',
  tertiaryGray: '#4E5B7B',
  transparent: 'rgba(0, 0, 0, 0)',
  transparentHalfBlack: 'rgba(0, 0, 0, 0.5)',
  transparentLightBlack: 'rgba(0, 0, 0, 0.09)',
  transparentShuttleGray: 'rgba(82, 92, 105, 0.11)',
  youtubePlayerOverlayBackground: '#000',

  // liki2.0 color palette:

  black: '#1d1d1d',
  white: '#fff',

  blue_500: '#0068FF',

  green_100: '#7ED321',
  green_200: '#1EA508',
  green_300: '#0E7727',
  green_400: '#1E2A3C',
  green_500: '#081521',

  grey_25: '#F6F7FB',
  grey_50: '#E9ECF5',
  grey_75: '#D7DEEE',
  grey_100: '#C0C9DF',
  grey_125: '#A7B1CA',

  pink_500: '#ED4592',

  clutch: '#BF0100',
  iotoakGEC: '#63B476',
  hopTrach_100: '#2B22A7',
  hopTrach_200: '#271187',
  orange: '#EF8031',
  orange_100: '#FFCA00'
};

const gradients = {
  primary: gradientsArray[0](colors.green_100, colors.green_200),
  primaryReverse: gradientsArray[1](colors.green_200, colors.green_100),
  purple: gradientsArray[1](colors.green_200, colors.green_100),
  purpleReverse: gradientsArray[1](colors.light, colors.green_100),
  backgroundLight: gradientsArray[1](colors.backgroundMedium, colors.grey_25),
  darkViolet: gradientsArray[2](colors.dark, colors.primaryBlack),
  diagonalPurple: gradientsArray[3](colors.green_100, colors.light),
  greenGradient: gradientsArray[4](colors.green_100, colors.green_200),
  greyGradient: gradientsArray[4](colors.grey_25, colors.grey_50),
  whiteToTransparent: gradientsArray[0](colors.white, colors.transparent),
  transparentToWhite: gradientsArray[0](colors.transparent, colors.white)
};

const borderRadius = {
  sm: '4px',
  lg: '8px',
  m: '16px',
  xl: '32px',
  button: '40px',
  circle: '50%'
};

const sizes = {
  layoutWidth: '1140px',
  layoutPadding: '30px',
  container: {
    xs: '390px',
    small: '540px',
    medium: '768px',
    large: '960px',
    xsLarge: '1140px'
  }
};

const contactFormVariant = {
  onWhite: {
    input: {
      background: 'rgba(117, 59, 255, 0.05)'
    },
    label: {
      color: '#242364'
    },
    checkbox: {
      color: 'rgba(9, 4, 50)',
      border: '2px solid rgba(9, 4, 50, 0.5)'
    },
    agreement: {
      color: '#242364'
    },
    check: {
      stroke: 'white'
    }
  },
  onViolet: {
    label: {
      color: 'white'
    },
    checkbox: {
      color: 'white',
      border: '2px solid #ffffff'
    },
    agreement: {
      color: 'white'
    },
    check: {
      stroke: '#6E2AFF'
    }
  }
};

export type ColorTypes = typeof colors;
export type FontTypes = typeof fonts;
export type FiltersTypes = typeof filters;
export type GradientTypes = typeof gradients;
export type SpacingTypes = typeof spacing;
export type BorderRadiusTypes = typeof borderRadius;
export type SizesTypes = typeof sizes;
export type ContactFormVariantTypes = typeof contactFormVariant;

export default {
  colors,
  fonts,
  filters,
  gradients,
  spacing,
  borderRadius,
  sizes,
  contactFormVariant
};
