export function partition<T>(inputArray: Array<T>, predicate: (item: T) => boolean): [Array<T>, Array<T>] {
  return inputArray.reduce(
    ([yes, no], item) => {
      if (predicate(item) === true) {
        yes.push(item);
      } else {
        no.push(item);
      }

      return [yes, no];
    },
    [[], []]
  );
}
