import { useState, useEffect } from 'react';
import { DocumentNode, gql } from '@apollo/client';

import { TermsConditionsProps } from 'components/pages/TermsConditions/TermsConditions';

import CMS from 'utils/cms/strapi';

const termsAndConditionsQuery = (locale: string): DocumentNode => gql`
  {
    termsAndConditions(locale: "${locale}") {
      title
      content
      metaTags {
        link {
          href
          rel
        }
        data
        meta {
          id
          property
          content
        }
        ogImage {
          url
        }
      }
    }
  }
`;

export const usePolicy = (locale: string): TermsConditionsProps => {
  const [page, setPage] = useState(null);

  useEffect(() => {
    const cms = new CMS([() => termsAndConditionsQuery(locale)]);
    cms.getContent().then(([termsAndConditions]) => {
      setPage(termsAndConditions.data.termsAndConditions[0]);
    });
  }, []);

  return page;
};

export default termsAndConditionsQuery;
