import Typography from 'components/atoms/Typography/Typography';
import React, { ElementType, FC, MouseEvent } from 'react';

import {
  StyledColorBox,
  StyledGenericContainer,
  StyledText,
  StyledUnderlineText,
  StyledWrapper
} from './GenericButton.styles';

export type GenericProps = {
  margin?: string;
  variant?:
    | 'contactUs_green'
    | 'primary'
    | 'secondary'
    | 'secondary-white'
    | 'secondary-dark'
    | 'tertiary-white'
    | 'tertiary-dark'
    | 'tertiary-clutch'
    | 'secondary_violet'
    | 'secondary_green'
    | 'secondary-liki'
    | 'secondary_blue'
    | 'secondary_pink';
  width?: string;
  height?: string;
  justifyContent?: string;
  overflow?: string;
  disabled?: boolean;
  as?: ElementType;
  href?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
};

export const GenericButton: FC<GenericProps> = ({
  children,
  margin,
  variant,
  width,
  height,
  justifyContent,
  overflow,
  disabled,
  as,
  href,
  onClick
}) => (
  <StyledGenericContainer
    variant={variant}
    margin={margin}
    width={width}
    height={height}
    disabled={disabled}
    as={as}
    href={href}
    onClick={onClick}
  >
    <StyledWrapper justifyContent={justifyContent} overflow={overflow}>
      {variant === 'primary' && <StyledColorBox variant={variant} />}
      {variant === 'tertiary-white' || variant === 'tertiary-dark' || variant === 'tertiary-clutch' ? (
        <StyledUnderlineText variant={variant}>{children}</StyledUnderlineText>
      ) : (
        <StyledText>
          <Typography variant="caption">{children}</Typography>
        </StyledText>
      )}
    </StyledWrapper>
  </StyledGenericContainer>
);
