import React, { FC } from 'react';

import useMedia from 'hooks/useMedia';

import Typography from 'components/atoms/Typography/Typography';

import { StyledOverflowColor, StyledContent, StyledTitle, StyledContainer } from './TermsHeader.styles';

export type TermsHeaderProps = {
  title: string;
};

const TermsHeader: FC<TermsHeaderProps> = ({ title }) => {
  const { isMobile } = useMedia();

  return (
    <StyledContainer>
      {!isMobile && <StyledOverflowColor />}
      <StyledContent>
        <StyledTitle>
          <Typography variant="h1">{title}</Typography>
        </StyledTitle>
      </StyledContent>
    </StyledContainer>
  );
};

export default TermsHeader;
