import styled from 'styled-components';
import { motion } from 'framer-motion';

export const WidgetPopupSpan = styled.span`
  position: absolute;
  top: 4px;
  right: 4px;
  display: inline-block;
  height: 20px;
  width: 20px;
  opacity: 0.2;
  transition: opacity 0.3s;
  cursor: pointer;
`;

export const AnimatedWidgetButtonPopup = styled(motion.div)`
  margin: 0;
  padding: 12px 20px 12px 14px;
  border: 2px solid ${(props) => props.theme.colors.green_100};
  vertical-align: baseline;
  position: absolute;
  left: -320px;
  width: 312px;
  background: ${(props) => props.theme.colors.white};
  border-radius: 15px;
  box-sizing: border-box;
  z-index: 6;
  cursor: pointer;
  bottom: 0;
`;
