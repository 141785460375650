import React, { ReactElement, useContext } from 'react';

import { useMinWidthQuery } from 'hooks/useMedia';
import { HeaderContext } from 'utils/HeaderThemeContext';

import { ActiveStyledNavbarLink, StyledNavBarItem } from '../../../Header.styles';

type HeadMenuProps = {
  id: string;
  href: string;
  text: string;
  mouseOverHandler: (id: string) => void;
};

const HeadMenu = ({ id, href, text, mouseOverHandler }: HeadMenuProps): ReactElement => {
  const isDesktop = useMinWidthQuery('xsLarge');
  const { theme: colorSchemeVariants } = useContext(HeaderContext);
  const currentColorScheme = isDesktop ? colorSchemeVariants.desktop : colorSchemeVariants.mobile;

  return (
    <StyledNavBarItem onMouseOver={() => mouseOverHandler(id)}>
      <ActiveStyledNavbarLink colorScheme={currentColorScheme} href={href}>
        {text}
      </ActiveStyledNavbarLink>
    </StyledNavBarItem>
  );
};

export default HeadMenu;
