import styled from 'styled-components';

export const StyledErrorBlank = styled.div`
  height: 14px;
  padding-top: 4px;
`;

export const StyledError = styled(StyledErrorBlank)`
  color: ${({ theme }) => theme.colors.persimmon};
  font-size: 10px;
  line-height: 14px;
`;
