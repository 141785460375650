import styled from 'styled-components';
import Image from '@next/image';

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledImage = styled(Image)`
  border-radius: 100%;
`;

export const StyledCommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: ${({ theme }) => theme.spacing(2)};
`;
