import React, { FC } from 'react';
import Image from '@next/image';

import fromCMS from 'utils/fromCMS';
import { LogoProps } from 'utils/types/Logo';
import { StyledLogo } from './Logo.styles';

export const Logo: FC<LogoProps['img']> = ({ url, alternativeText }) => (
  <StyledLogo>
    <Image src={fromCMS(url)} alt={alternativeText} layout="fill" objectFit="contain" />
  </StyledLogo>
);
