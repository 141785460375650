module.exports = {
  xSmall: 450,
  small: 576,
  medium: 768,
  mobileMax: 991,
  large: 992,
  xLarge: 1100,
  xsLarge: 1160,
  xmLarge: 1300,
  xxLarge: 1360,
  xxsLarge: 1600,
  xxxLarge: 1920,
  fourK: 2560
};
