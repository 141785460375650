import styled from 'styled-components';

import { StyledContent as ArticleHeaderContent } from 'components/organisms/ArticleHeader/ArticleHeader.styles';

import { mq } from 'theme/media';

export const StyledContent = styled(ArticleHeaderContent)`
  color: ${({ theme }) => theme.colors.primaryBlack};
  padding: ${({ theme }) => theme.spacing(0, 0, 4)};

  ${mq.large`
    color: ${({ theme }) => theme.colors.white};
    padding: ${({ theme }) => theme.spacing(11, 0, 13)};
  `}
`;

export const StyledOverflowColor = styled.div`
  background: ${({ theme }) => theme.colors.green_100};
  width: 100%;
  height: 580px;
  position: absolute;
  top: 0;
  left: 0;
`;

export const StyledTitle = styled.div`
  padding-top: ${({ theme }) => theme.spacing(4)};

  ${mq.large`
    padding-top: ${({ theme }) => theme.spacing(21)};
    margin-bottom: ${({ theme }) => theme.spacing(5)};
  `}
`;

export const StyledContainer = styled.div`
  position: relative;
  overflow: hidden;
`;
