import React, { FC, useState } from 'react';
import { AnimatePresence } from 'framer-motion';

import FormInputType from 'utils/types/FormInput';
import useMedia from 'hooks/useMedia';

import { PopupWidget } from './PopupWidget';
import { ButtonWidget } from './ButtonWidget';
import { WindowWidget } from './WindowWidget';

import { WidgetButtonWrapper } from './ContactWidget.styles';

export type ContactWidgetProps = {
  contactFormInputs: Array<FormInputType>;
  buttonText: string;
  popupName: string;
  popupComment: string;
  popupImage: {
    alternativeText: string;
    url: string;
  };
};

export const ContactWidget: FC<ContactWidgetProps> = ({
  contactFormInputs,
  buttonText,
  popupName,
  popupComment,
  popupImage
}) => {
  const [isWindow, setWindow] = useState(false);
  const [isPopup, setPopup] = useState(false);

  const { isMobile } = useMedia();

  return (
    <>
      <AnimatePresence>
        {isWindow && (
          <WindowWidget
            contactFormInputs={contactFormInputs}
            buttonText={buttonText}
            hideWindow={() => setWindow(false)}
            isMobile={isMobile}
          />
        )}
      </AnimatePresence>
      {!isWindow && (
        <WidgetButtonWrapper
          onClick={() => {
            setWindow(!isWindow);
            setPopup(false);
          }}
          onMouseLeave={() => setPopup(false)}
          onMouseOver={() => setPopup(true)}
        >
          <AnimatePresence>
            {isPopup && <PopupWidget popupName={popupName} popupComment={popupComment} popupImage={popupImage} />}
          </AnimatePresence>
          <ButtonWidget />
        </WidgetButtonWrapper>
      )}
    </>
  );
};
