import React, { FC } from 'react';

import { AppLink } from 'components/AppLink/AppLink';

import { GenericProps, GenericButton } from '../GenericButton';

export const LinkedButton: FC<GenericProps> = ({
  children,
  margin,
  justifyContent,
  overflow,
  variant = 'primary',
  width = '208px',
  height = '56px',
  disabled,
  href
}) => (
  <GenericButton
    as={AppLink}
    variant={variant}
    margin={margin}
    width={width}
    height={height}
    justifyContent={justifyContent}
    overflow={overflow}
    disabled={disabled}
    href={href}
  >
    {children}
  </GenericButton>
);
