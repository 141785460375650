import React, { FC } from 'react';

import { useTranslations } from 'utils/translations';

import {
  StyledFormSuccess,
  StyledFormSuccessInner,
  StyledFormSuccessInnerIcon,
  StyledFormSuccessInnerText,
  StyledFormInnerBox
} from './Success.styles';

export const Success: FC = () => {
  const t = useTranslations();

  return (
    <StyledFormSuccess>
      <StyledFormSuccessInner>
        <StyledFormSuccessInnerIcon />
        <StyledFormSuccessInnerText>{t('contact-form.success')}</StyledFormSuccessInnerText>
      </StyledFormSuccessInner>
      <StyledFormInnerBox />
    </StyledFormSuccess>
  );
};

export default Success;
