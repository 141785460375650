import styled from 'styled-components';

import { mq } from 'theme/media';

export const StyledBodyWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(-13)};

  ${mq.large`
    margin: ${({ theme }) => theme.spacing(-13, 0, -9)};
  `}
`;
