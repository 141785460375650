import styled, { css } from 'styled-components';
import { mq } from 'theme/media';

export const StyledNavBarItem = styled.div<{ isMenuOpen: boolean }>`
  position: relative;
  display: inline-block;

  ${({ isMenuOpen }) =>
    isMenuOpen &&
    css`
      padding-bottom: ${({ theme }) => theme.spacing(3)};
    `}

  ${mq.xsLarge`
  margin-right: ${({ theme }) => theme.spacing(4)};
  padding-bottom: 0px;
  `}
`;

export const StyledLanguageMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.spacing(3)};

  ${mq.xsLarge`
    justify-content: flex-start;
    gap: 10px;
    padding-bottom: 0px;
  `}
`;

export const StyledImageContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const StyledLanguageMenu = styled.div<{
  isMobile?: boolean;
}>`
  position: absolute;
  top: 64px;
  background: ${({ theme, isMobile }) => (isMobile ? 'none' : theme.colors.white)};
  width: 245px;
  height: 112px;
  box-shadow: ${({ isMobile }) =>
    isMobile ? 'none' : '0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3)'};
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  z-index: 2;

  ${({ isMobile }) =>
    isMobile &&
    css`
      position: relative;
      top: 0px;
      padding-left: 24px;
    `}
`;

export const StyledLanguageMenuItem = styled.div`
  padding: 13px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.grey_25};
  }
`;

export const StyledLanguageSelectorArrow = styled.span<{ isLanguageMenuOpen: boolean }>`
  rotate: ${({ isLanguageMenuOpen }) => (isLanguageMenuOpen ? '0' : '270deg')};

  ${mq.xsLarge`
    rotate: ${({ isLanguageMenuOpen }) => (isLanguageMenuOpen ? '180deg' : '0deg')};
  `}
`;

export const StyledLanguageImageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
