class Cookies {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static setCookie(name: string, value: any, days: number): void {
    let expires = '';

    if (days) {
      const date = new Date();

      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toUTCString()}`;
    }

    document.cookie = `${name}=${JSON.stringify(value) || ''}${expires}; path=/`;
  }

  static getCookie(name: string, cookiesToConvert = ''): any {
    if (typeof document === 'undefined' && !cookiesToConvert) {
      return null;
    }

    const nameEQ = `${name}=`;
    const cookies = cookiesToConvert ? cookiesToConvert.split(';') : document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];

      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1, cookie.length);
      }

      if (cookie.indexOf(nameEQ) === 0) {
        return JSON.parse(cookie.substring(nameEQ.length, cookie.length));
      }
    }

    return null;
  }

  static eraseCookie(name: string): void {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
}

export default Cookies;
