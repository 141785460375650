import React, { FC } from 'react';
import { FieldError } from 'react-hook-form';

import { StyledError, StyledErrorBlank } from './ErrorMessage.styles';

type ErrorMessageProps = {
  error: FieldError;
};

const errors = {
  required: 'This field is required',
  pattern: {
    email: 'Enter a valid email address',
    phone: 'Enter a valid phone number'
  },
  maxLength: 'This text is too long',
  minLength: 'This text is too short'
};

export const ErrorMessage: FC<ErrorMessageProps> = ({ error }) => {
  if (error) {
    if (error.type === 'pattern') {
      return <StyledError>{errors[error.type][error.ref.name]}</StyledError>;
    }

    return <StyledError>{errors[error.type]}</StyledError>;
  }

  return <StyledErrorBlank />;
};
