import React, { FC } from 'react';

import { ArticleBody } from 'components/organisms/ArticleBody/ArticleBody';
import useMedia from 'hooks/useMedia';
import TermsHeader from './TermsHeader/TermsHeader';

import { StyledBodyWrapper } from './TermsConditions.styles';

export type TermsConditionsProps = {
  title: string;
  content: string;
};

const TermsConditions: FC<TermsConditionsProps> = ({ title, content }) => {
  const { isMobile } = useMedia();

  return isMobile ? (
    <StyledBodyWrapper>
      <ArticleBody content={content} childrenBefore={<TermsHeader title={title} />} />
    </StyledBodyWrapper>
  ) : (
    <>
      <TermsHeader title={title} />
      <StyledBodyWrapper>
        <ArticleBody content={content} />
      </StyledBodyWrapper>
    </>
  );
};

export default TermsConditions;
