import React, { FC } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import {
  BottomBadge,
  RedText,
  ReviewsText,
  RightSide,
  StarsWrapper,
  StyledClutch,
  StyledClutchContainer,
  StyledClutchSquare,
  StyledIcon,
  StyledLink,
  TopLine
} from './ClutchWidget.styles';

type ClutchWidgetProps = {
  rate: number;
  reviews: number;
  link: string;
  option?: 'rectangle' | 'square';
};

const ClutchWidget: FC<ClutchWidgetProps> = ({ rate, reviews, link, option = 'rectangle' }) => {
  const renderRectangleWidget = () => (
    <StyledClutchContainer>
      <Link href={link}>
        <a rel="noopener noreferrer no-follow">
          <StyledClutch>
            <StyledIcon>
              <Image src="/logos/clutchIcon.svg" alt="Clutch Icon" width="23px" height="25px" />
            </StyledIcon>
            <RightSide>
              <TopLine>
                <ReviewsText>{rate}</ReviewsText>
                <StarsWrapper>
                  {[...Array(5)].map((x, idx) => (
                    <Image src="/img/star.svg" alt="Clutch Icon" width="16px" height="16px" key={`clutchStar-${idx}`} />
                  ))}
                </StarsWrapper>
              </TopLine>
              <StyledLink>Based on {reviews} Clutch reviews</StyledLink>
            </RightSide>
          </StyledClutch>
        </a>
      </Link>
      <Image src="/logos/clutch_emblemat.png" alt="Clutch Emblemat" width="74px" height="80px" />
    </StyledClutchContainer>
  );

  const renderSquareWidget = () => (
    <Link href={link}>
      <a rel="noopener noreferrer no-follow">
        <StyledClutchSquare>
          <TopLine>
            <StarsWrapper>
              <Image src="/img/star.svg" alt="Clutch Icon" width="16px" height="16px" />
            </StarsWrapper>
            <ReviewsText>
              <span>{rate}</span>/5
            </ReviewsText>
          </TopLine>
          <RedText>{reviews} REVIEWS</RedText>
          <BottomBadge>
            Powered by <Image src="/logos/ClutchLongLogo.svg" alt="Clutch Logo" width="27px" height="8px" />
          </BottomBadge>
        </StyledClutchSquare>
      </a>
    </Link>
  );

  return option === 'rectangle' ? renderRectangleWidget() : renderSquareWidget();
};

export default ClutchWidget;
