import styled, { css, DefaultTheme } from 'styled-components';

import { TypographyProps } from './Typography';

const handleTypoColor = (color: string, theme: DefaultTheme): string => {
  switch (color) {
    case 'liki-green':
      return theme.colors.green_100;
    case 'primary':
      return theme.colors.green_400;
    case 'secondary':
      return theme.colors.white;
    case 'primaryGray':
      return theme.colors.primaryGray;
    case 'secondaryGray':
      return theme.colors.secondaryGray;
    case 'tertiaryGray':
      return theme.colors.tertiaryGray;
    case 'darkGreen':
      return theme.colors.green_400;
    case 'green_200':
      return theme.colors.green_200;
    case 'green_300':
      return theme.colors.green_300;
    case 'darkerGreen':
      return theme.colors.green_500;
    default:
      return theme.colors.green_400;
  }
};

export const StyledTypography = styled.span<{
  color: TypographyProps['color'];
  weight: TypographyProps['weight'];
  align: TypographyProps['align'];
  ellipsisLines: TypographyProps['ellipsisLines'];
  transform: TypographyProps['transform'];
}>`
  display: inline-block;
  letter-spacing: 0.01em;

  ${({ theme, color }) =>
    color !== 'inherit' &&
    css`
      color: ${handleTypoColor(color, theme)};
    `}

  ${({ weight }) =>
    weight &&
    css`
      font-weight: ${weight};
    `}

  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}

  ${({ transform }) =>
    transform &&
    css`
      text-transform: ${transform};
    `}

    ${({ ellipsisLines }) =>
    ellipsisLines &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: ${ellipsisLines};
      -webkit-box-orient: vertical;
      overflow: hidden;

      ${ellipsisLines === 1 &&
      css`
        word-break: break-all;
      `}
    `}
`;

export const StyledH1 = styled(StyledTypography)`
  ${({ theme }) => theme.fonts.h1};
`;

export const StyledH1Small = styled(StyledTypography)`
  ${({ theme }) => theme.fonts.h1small};
`;

export const StyledH1Highlighted = styled(StyledTypography)`
  ${({ theme }) => theme.fonts.h1Highlighted};
`;

export const StyledH2 = styled(StyledTypography)`
  ${({ theme }) => theme.fonts.h2};
`;

export const StyledH3 = styled(StyledTypography)`
  ${({ theme }) => theme.fonts.h3};
`;

export const StyledH3bigger = styled(StyledTypography)`
  ${({ theme }) => theme.fonts.h3bigger};
`;

export const StyledH4bigger = styled(StyledTypography)`
  ${({ theme }) => theme.fonts.h4bigger};
`;

export const StyledH4 = styled(StyledTypography)`
  ${({ theme }) => theme.fonts.h4};
`;

export const StyledH5 = styled(StyledTypography)`
  ${({ theme }) => theme.fonts.h5};
`;

export const StyledH6 = styled(StyledTypography)`
  ${({ theme }) => theme.fonts.h6}
`;

export const StyledBody1 = styled(StyledTypography)`
  ${({ theme }) => theme.fonts.body1};
`;

export const StyledBody2 = styled(StyledTypography)`
  ${({ theme }) => theme.fonts.body2};
`;
export const StyledBody3 = styled(StyledTypography)`
  ${({ theme }) => theme.fonts.body3};
`;

export const StyledCounter = styled(StyledTypography)`
  ${({ theme }) => theme.fonts.counter};
`;

export const StyledCaption = styled(StyledTypography)`
  ${({ theme }) => theme.fonts.caption};
`;

export const StyledSubtitle = styled(StyledTypography)`
  ${({ theme }) => theme.fonts.subtitle};
`;

export const StyledLabel = styled(StyledTypography)`
  ${({ theme }) => theme.fonts.label};
`;
