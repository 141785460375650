import { darken, rgba } from 'polished';
import styled, { css, DefaultTheme, keyframes } from 'styled-components';

import { GenericProps } from './GenericButton';

const getColor = (variant, theme) => {
  switch (variant) {
    case 'secondary_violet':
      return `${theme.colors.darkViolet}`;
    case 'secondary_green':
      return `${theme.colors.buttonGreen}`;
    case 'secondary':
      return `${theme.colors.green_300}`;
    case 'secondary_blue':
      return `${theme.colors.blue_500}`;
    case 'secondary_pink':
      return `${theme.colors.pink_500}`;
    default:
      return `${theme.colors.white}`;
  }
};

const buttonVariants = (theme: DefaultTheme) => ({
  primary: {
    main: {
      background: `${theme.colors.green_100}`,
      border: 'none',
      boxShadow: `5px 7px 25px 0 ${rgba(theme.colors.green_100, 0.4)}`,
      overflow: 'hidden',

      '&::before, &::after': {
        content: '""',
        position: 'absolute' as const,
        height: '100%',
        width: '100%',
        bottom: '100%',
        borderRadius: `${theme.borderRadius.button}`,
        left: 0,
        zIndex: -1,
        transition: 'transform 0.3s',
        transitionTimingFunction: 'cubic-bezier(0.75, 0, 0.125, 1)'
      },
      '&::before': {
        backgroundColor: `${rgba(theme.colors.medium, 0.5)}`
      },
      '&::after': {
        backgroundColor: `${theme.colors.medium}`
      },
      '&:hover': {
        '&::before, &::after': {
          transform: 'translate3d(0, 100%, 0)'
        },
        '&::after': {
          transitionDelay: '0.175s'
        }
      },

      '&:disabled': {
        background: theme.colors.lighter,
        '& span': {
          color: darken(0.8, theme.colors.grey_25),
          opacity: 0.55
        },
        '& div': {
          background: theme.colors.lighter
        },
        '&::before, &::after': {
          display: 'none'
        }
      }
    },
    colorBox: {}
  },
  secondary: {
    main: {
      background: `${theme.colors.white}`,
      border: 'none',
      boxShadow: `5px 7px 25px 0 ${rgba(theme.colors.green_100, 0.4)}`,
      overflow: 'hidden',

      '&:hover': {
        boxShadow: `5px 7px 25px 0 ${theme.colors.white}`
      },

      '&:active': {
        boxShadow: 'none'
      }
    },
    colorBox: {}
  },
  contactUs_green: {
    main: {
      border: 'none',
      overflow: 'hidden',
      transition: 'color 0.3s',
      transitionTimingFunction: 'cubic-bezier(0.75, 0, 0.125, 1)',
      '&::before, &::after': {
        content: '""',
        position: 'absolute' as const,
        zIndex: -1,
        borderRadius: 'inherit'
      },
      '&::before': {
        background: `${theme.colors.lighter}`,
        width: '100%',
        height: '100%',
        transform: 'translate3d(0, 100%, 0)',
        transition: 'transform 0.3s'
      },
      '&::after': {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: `${theme.colors.green_300}`
      },
      '&:hover': {
        '&::before': {
          transform: 'translate3d(0, 0, 0)'
        }
      },
      '&:disabled': {
        '&:after': { background: theme.colors.grey_75 },
        '& span': {
          color: `${theme.colors.green_400}`
        },
        '&::before': {
          display: 'none'
        }
      }
    },
    colorBox: {}
  },
  secondary_violet: {
    main: {
      background: `${rgba(theme.colors.darkViolet, 0.5)}`,
      color: `${theme.colors.darkViolet}`,
      border: 'none',
      overflow: 'hidden',
      transition: 'color 0.3s',
      transitionTimingFunction: 'cubic-bezier(0.75, 0, 0.125, 1)',
      '&::before, &::after': {
        content: '""',
        position: 'absolute' as const,
        zIndex: -1,
        borderRadius: 'inherit'
      },
      '&::before': {
        background: `${rgba(theme.colors.darkViolet, 0.5)}`,
        width: '100%',
        height: '100%',
        transform: 'translate3d(0, 100%, 0)',
        transition: 'transform 0.3s'
      },
      '&::after': {
        top: '2px',
        left: '2px',
        right: '2px',
        bottom: '2px',
        background: `${theme.colors.white}`
      },
      '&:hover': {
        '&::before': {
          transform: 'translate3d(0, 0, 0)'
        }
      }
    },
    colorBox: {}
  },
  secondary_green: {
    main: {
      background: `${rgba(theme.colors.buttonGreen, 0.5)}`,
      color: `${theme.colors.buttonGreen}`,
      border: 'none',
      overflow: 'hidden',
      transition: 'color 0.3s',
      transitionTimingFunction: 'cubic-bezier(0.75, 0, 0.125, 1)',
      '&::before, &::after': {
        content: '""',
        position: 'absolute' as const,
        zIndex: -1,
        borderRadius: 'inherit'
      },
      '&::before': {
        background: `${rgba(theme.colors.buttonGreen, 0.5)}`,
        width: '100%',
        height: '100%',
        transform: 'translate3d(0, 100%, 0)',
        transition: 'transform 0.3s'
      },
      '&::after': {
        top: '2px',
        left: '2px',
        right: '2px',
        bottom: '2px',
        background: `${theme.colors.white}`
      },
      '&:hover': {
        '&::before': {
          transform: 'translate3d(0, 0, 0)'
        }
      }
    },
    colorBox: {}
  },
  secondary_blue: {
    main: {
      background: `${theme.colors.transparent}`,
      color: `${theme.colors.blue_500}`,
      border: `2px solid ${rgba(theme.colors.blue_500, 0.5)}`,
      overflow: 'hidden',
      transition: 'border 0.2s',

      '&:hover': {
        border: `2px solid ${theme.colors.blue_500}`
      }
    },
    colorBox: {}
  },
  secondary_pink: {
    main: {
      background: `${theme.colors.transparent}`,
      color: `${theme.colors.pink_500}`,
      border: `2px solid ${rgba(theme.colors.pink_500, 0.5)}`,
      overflow: 'hidden',
      transition: 'border 0.2s',

      '&:hover': {
        border: `2px solid ${theme.colors.pink_500}`
      }
    },
    colorBox: {}
  },
  'secondary-liki': {
    main: {
      background: `${rgba(theme.colors.green_200, 0.5)}`,
      border: 'none',
      overflow: 'hidden',
      transition: 'color 0.3s',
      transitionTimingFunction: 'cubic-bezier(0.75, 0, 0.125, 1)',
      '&::before, &::after': {
        content: '""',
        position: 'absolute' as const,
        zIndex: -1,
        borderRadius: 'inherit'
      },
      '&::before': {
        background: `${rgba(theme.colors.green_200, 0.5)}`,
        width: '100%',
        height: '100%',
        transform: 'translate3d(0, 100%, 0)',
        transition: 'transform 0.3s'
      },
      '&::after': {
        top: '2px',
        left: '2px',
        right: '2px',
        bottom: '2px',
        background: `${theme.colors.green_400}`
      },
      '&:hover': {
        '&::before': {
          transform: 'translate3d(0, 0, 0)'
        }
      }
    },
    colorBox: {}
  },
  'secondary-white': {
    main: {
      border: 'none',
      overflow: 'hidden',
      transition: 'color 0.3s',
      transitionTimingFunction: 'cubic-bezier(0.75, 0, 0.125, 1)',
      '&::before, &::after': {
        content: '""',
        position: 'absolute' as const,
        zIndex: -1,
        borderRadius: 'inherit'
      },
      '&::before': {
        background: `${theme.colors.lighter}`,
        width: '100%',
        height: '100%',
        transform: 'translate3d(0, 100%, 0)',
        transition: 'transform 0.3s'
      },
      '&::after': {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: `${theme.colors.green_100}`
      },
      '&:hover': {
        '&::before': {
          transform: 'translate3d(0, 0, 0)'
        }
      },
      '&:disabled': {
        // background: `${rgba(theme.colors.dark, 0.3)}`,
        '&:after': { background: theme.colors.primaryGray },
        '& span': {
          color: `${theme.colors.white}`
        },
        '&::before': {
          display: 'none'
        }
      }
    },
    colorBox: {}
  },
  'secondary-dark': {
    main: {
      background: `${rgba(theme.colors.green_100, 0.8)}`,
      border: 'none',
      overflow: 'hidden',
      '& span': {
        color: `${rgba(theme.colors.green_100, 0.8)}`
      },
      '&::before, &::after': {
        content: '""',
        position: 'absolute' as const,
        zIndex: -1,
        borderRadius: 'inherit'
      },
      '&::before': {
        background: `${theme.colors.light}`,
        width: '100%',
        height: '100%',
        transform: 'translate3d(0, 100%, 0)',
        transition: 'transform 0.3s'
      },
      '&::after': {
        top: '2px',
        left: '2px',
        right: '2px',
        bottom: '2px',
        background: `${theme.colors.white}`
      },
      '&:hover': {
        '&::before': {
          transform: 'translate3d(0, 0, 0)'
        }
      },
      '&:active': {
        background: `linear-gradient(268.28deg, ${rgba(theme.colors.green_100, 0.24)} 3.87%, ${rgba(
          theme.colors.light,
          0.24
        )} 92.97%)`
      },
      '&:disabled': {
        background: rgba(theme.colors.medium, 0.1),
        border: `1px solid ${rgba(theme.colors.medium, 0.1)}`,
        '& span': {
          color: rgba(theme.colors.medium, 0.2)
        }
      }
    },
    colorBox: {}
  },
  'tertiary-white': {
    main: {
      background: 'transparent',
      display: 'flex',
      flexDirection: 'column' as const,
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none',
      '& span': {
        color: theme.colors.green_300,
        textTransform: 'uppercase' as const,
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '14px'
      },
      '&:active': {
        background: `linear-gradient(268.28deg, ${rgba(theme.colors.green_100, 0.2)} 3.87%, ${rgba(
          theme.colors.light,
          0.2
        )} 92.97%)`
      },
      '&:disabled': {
        background: 'transparent',
        '& span': {
          color: theme.colors.medium,
          fontWeight: 800,
          fontSize: '10px',
          lineHeight: '14px',
          textTransform: 'uppercase' as const,
          textDecoration: 'underline'
        }
      }
    },
    colorBox: {}
  },
  'tertiary-dark': {
    main: {
      background: 'transparent',
      display: 'flex',
      flexDirection: 'column' as const,
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none',
      '& span': {
        color: 'white',
        textTransform: 'uppercase' as const,
        fontWeight: 800,
        fontSize: '10px',
        lineHeight: '14px'
      },
      '&:active': {
        background: `linear-gradient(268.28deg, ${rgba(theme.colors.green_100, 0.2)} 3.87%, ${rgba(
          theme.colors.light,
          0.2
        )} 92.97%);`
      },
      '&:disabled': {
        background: 'transparent',
        '& span': {
          color: theme.colors.lighter,
          fontWeight: 800,
          fontSize: '10px',
          lineHeight: '14px',
          textTransform: 'uppercase' as const,
          textDecoration: 'underline'
        }
      }
    },
    colorBox: {}
  },
  'tertiary-clutch': {
    main: {
      background: 'transparent',
      display: 'flex',
      flexDirection: 'column' as const,
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none',
      '& span': {
        color: theme.colors.clutch,
        textTransform: 'uppercase' as const,
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '14px'
      },
      '&:active': {
        background: `linear-gradient(268.28deg, ${rgba(theme.colors.clutch, 0.2)} 3.87%, ${rgba(
          theme.colors.clutch,
          0.2
        )} 92.97%)`
      },
      '&:disabled': {
        background: 'transparent',
        '& span': {
          color: theme.colors.clutch,
          fontWeight: 800,
          fontSize: '10px',
          lineHeight: '14px',
          textTransform: 'uppercase' as const,
          textDecoration: 'underline'
        }
      }
    },
    colorBox: {}
  }
});

export const onHover = keyframes`
  0% {
    width: 100%;
  }
  50% {
    transform: translateX(120%);
    width: 100%;
  }
  51% {
    transform: translateX(0);
    width: 0;
  }
  60% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`;

export const StyledUnderlineText = styled.span<Partial<GenericProps>>`
  position: relative;
  overflow: hidden;
  white-space: nowrap;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    height: 1px;
    width: 100%;
    background: ${({ variant, theme }) => {
      if (variant === 'tertiary-white') {
        return theme.colors.green_300;
      } else if (variant === 'tertiary-clutch') {
        return theme.colors.clutch;
      } else {
        return theme.colors.white;
      }
    }};
  }
`;

export const StyledGenericContainer = styled.div<GenericProps>`
  ${({ theme, variant }) => css(buttonVariants(theme)[variant].main)};

  z-index: 2;
  position: relative;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  color: ${({ variant, theme }) => getColor(variant, theme)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  margin: ${({ margin }) => margin};
  border-radius: ${({ theme }) => theme.borderRadius.button};
  padding: 0;
  display: flex;
  outline: none;
  user-select: none;
  &:hover {
    ${StyledUnderlineText} {
      &:after {
        animation: ${onHover} 1s;
      }
    }
  }
`;

export const StyledColorBox = styled.div<Partial<GenericProps>>`
  position: absolute;
  width: 30%;
  height: 120px;
  left: ${({ theme }) => theme.spacing(-1)};
  top: ${({ theme }) => theme.spacing(-6)};
  transform: rotate(45deg);

  ${({ variant, disabled, theme }) => !disabled && css(buttonVariants(theme)[variant].colorBox)};
`;

export const StyledWrapper = styled.div<Partial<GenericProps>>`
  overflow: ${({ overflow }) => overflow || 'hidden'};
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.button};
`;

export const StyledText = styled.span`
  position: relative;
`;
