import { apiClient } from 'utils/api/cms';
import { CMSProvider } from 'utils/cms';

/* eslint-disable */
class CMS implements CMSProvider {
  readonly _queries: Function[];

  constructor(queries: Function[]) {
    this._queries = queries;
  }

  getContent() {
    return Promise.all(
      this._queries
        .map((query) => (typeof query === 'function' ? apiClient.query({ query: query() }) : null))
        .filter(Boolean)
    );
  }

  setCreate() {
    return Promise.all(this._queries.map((query) => apiClient.mutate({ mutation: query() })).filter(Boolean));
  }
}

export default CMS;
