import React, { ReactElement, useContext, useState } from 'react';
import Image from 'next/image';
import { AnimatePresence } from 'framer-motion';

import { useMinWidthQuery } from 'hooks/useMedia';
import { isMobileVisible, MenuItem } from 'utils/types/Menu';
import { HeaderContext } from 'utils/HeaderThemeContext';

import {
  StyledMenuItem,
  ActiveStyledNavbarLink,
  StyledSelectorArrow,
  StyledSubmenuWrapper
} from '../../../Header.styles';

type MobileMenuItemProps = {
  isOpen: boolean;
  animationDuration: number;
  href: string;
  text: string;
  isSubmenu?: boolean;
  submenuItems?: Array<MenuItem>;
};

const MobileMenuItem = ({
  isOpen,
  animationDuration,
  href,
  text,
  isSubmenu = false,
  submenuItems
}: MobileMenuItemProps): ReactElement => {
  const isDesktop = useMinWidthQuery('xsLarge');
  const { theme: colorSchemeVariants } = useContext(HeaderContext);
  const currentColorScheme = isDesktop ? colorSchemeVariants.desktop : colorSchemeVariants.mobile;

  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenuOpen = () => setMenuOpen((previousState) => !previousState);

  return (
    <>
      <StyledMenuItem isOpen={isOpen} duration={animationDuration} isSubmenu={isSubmenu} isSubmenuOpen={isMenuOpen}>
        <ActiveStyledNavbarLink href={href} colorScheme={currentColorScheme}>
          {text}
        </ActiveStyledNavbarLink>
        {!!submenuItems && (
          <StyledSelectorArrow onClick={toggleMenuOpen} isSubmenu={isSubmenu} isMenuOpen={isMenuOpen}>
            <Image src="/icons/vector.svg" alt="arrow icon" width="12px" height="7px" />
          </StyledSelectorArrow>
        )}
      </StyledMenuItem>
      <AnimatePresence>
        <StyledSubmenuWrapper isMenuOpen={isMenuOpen}>
          {submenuItems?.filter(isMobileVisible)?.map(({ id: submenuId, animation, href, text }) => (
            <MobileMenuItem
              key={`mobile-submenu-item-${submenuId}`}
              isOpen={isOpen}
              animationDuration={animation}
              href={href}
              text={text}
            />
          ))}
        </StyledSubmenuWrapper>
      </AnimatePresence>
    </>
  );
};

export default MobileMenuItem;
