import styled from 'styled-components';
import { motion } from 'framer-motion';

export const AnimatedWindowPanel = styled(motion.div)`
  top: 0;
  right: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  max-height: 100%;
  max-width: 510px;
  border-radius: 4px;
  z-index: 6;
  overflow: hidden;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
`;

export const WindowButton = styled.button`
  position: absolute;
  top: 12px;
  right: 23px;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  border: 0;
  transition: opacity 0.3s;
  opacity: 0.5;
  cursor: pointer;
  outline: 0;
  background-color: ${(props) => props.theme.colors.green_100};
  z-index: 2;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 15px;
    background-color: ${(props) => props.theme.colors.white};
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 15px;
    background-color: ${(props) => props.theme.colors.white};
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

export const WindowContent = styled.div`
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;

  box-sizing: border-box;
  outline: 0;
`;

export const WindowScrollable = styled.div`
  box-sizing: border-box;
  outline: 0;
  overflow: hidden auto;
  background-color: ${(props) => props.theme.colors.white};
  position: absolute;
  inset: 0;
`;

export const WindowScroll = styled.div`
  box-sizing: border-box;
  outline: 0;

  height: 1px;
  opacity: 0;
`;

export const WindowWrapper = styled.div`
  box-sizing: border-box;
  outline: 0;
  display: contents;

  position: relative;
  background-color: ${(props) => props.theme.colors.white};
  min-height: 250px;
  max-width: 700px;
  margin: 0 auto;
  transition: min-height 180ms ease;
  word-break: break-word;

  flex-direction: column;
  justify-content: space-between;
`;

export const WindowHeader = styled.div`
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;

  box-sizing: border-box;
  outline: 0;
`;

export const WindowFormContent = styled.div`
  box-sizing: border-box;
  outline: 0;
  padding: 14px 31px;
  padding-left: 31px;
  padding-right: 31px;
  flex: 1;

  input,
  textarea {
    border-radius: 4px;
    background-image: linear-gradient(
      ${(props) => props.theme.colors.transparent} 0,
      ${(props) => props.theme.colors.transparent} 100%
    );
    border: 1px solid ${(props) => props.theme.colors.green_100};
    background-color: ${(props) => props.theme.colors.transparent};
    color: ${(props) => props.theme.colors.black};
    width: 100%;
    letter-spacing: -0.3px;
  }

  input {
    height: 52px;
  }

  textarea {
    height: 156px;
    padding-top: 16px;
    overflow-y: auto;
    resize: none;
  }

  button {
    width: 100%;
  }

  button:last-child {
    margin: 0;
  }

  form {
    position: relative;
    display: block;
    background-color: ${(props) => props.theme.colors.white};
    min-height: 250px;
    max-width: 700px;
    margin: 0 auto;
    transition: min-height 180ms ease;
    word-break: break-word;

    & > div {
      grid-template-areas: 'top top' 'left' 'bottom bottom';
    }
  }
`;

export const WindowOverlay = styled(motion.div)`
  position: fixed;
  inset: 0;
  background-color: ${(props) => props.theme.colors.transparentHalfBlack};
  z-index: 5;
`;
