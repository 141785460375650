import getConfig from 'next/config';

const config = getConfig();

const fromCMS = (url: string): string => {
  if (config) {
    const { publicRuntimeConfig } = getConfig();

    return `${publicRuntimeConfig.publicCMSUri}${url}`;
  }

  return url;
};

export default fromCMS;
