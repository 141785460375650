import React, { FC } from 'react';

import IconResolver from 'components/atoms/icons/IconResolver/IconResolver';

import theme from 'theme/theme';

import { StyledFormLoader, StyledFormLoaderIcon } from './Loader.styles';

export const Loader: FC = () => (
  <StyledFormLoader>
    <StyledFormLoaderIcon>
      <IconResolver id="loader" color={theme.colors.candlelight} />
    </StyledFormLoaderIcon>
  </StyledFormLoader>
);

export default Loader;
