import React, { FC } from 'react';
import Image from 'next/image';
import { StyledShapeContainer } from './Shape.styles';

export type ShapeProps = {
  src: string;
  width: number;
  height: number;
  position: 'absolute' | 'relative';
  left?: string;
  right?: string;
  bottom?: string;
  top?: string;
  zIndex?: number;
};

export const Shape: FC<ShapeProps> = ({ src, width, height, top, left, right, bottom, position, zIndex }) => (
  <StyledShapeContainer top={top} bottom={bottom} left={left} right={right} position={position} zIndex={zIndex}>
    <Image src={src} width={width} height={height} quality={100} />
  </StyledShapeContainer>
);
