const ROUTES = {
  HOME: {
    href: '/',
    name: 'Home'
  },
  ABOUT: {
    href: '/about-us',
    name: 'About'
  },
  EXTENSION: {
    href: '/team-extension',
    name: 'Team Extension'
  },
  SINGLE_TECHNOLOGY_EXTENSION: {
    href: '/team-extension/[slug]',
    name: 'Single technology extension'
  },
  SOFTWARE: {
    href: '/custom-software',
    name: 'Custom Software'
  },
  CAREERS: {
    href: '/jobs',
    name: 'Careers'
  },
  SINGLE_JOB_OFFER: {
    href: '/jobs/slug',
    name: 'Job offer'
  },
  SERVICES: {
    href: '/services',
    name: 'Services'
  },
  TECH_BLOCK: {
    href: '/blog',
    name: 'Tech Block'
  },
  BLOG: {
    href: '/blog',
    name: 'Blog'
  },
  BLOG_ARTICLE: {
    href: '/blog/[slug]',
    name: 'Blog article'
  },
  CASE_STUDY: {
    href: '/case-studies',
    name: 'Case studies'
  },
  SINGLE_CASE_STUDY: {
    href: '/case-studies/slug',
    name: 'Case study'
  },
  TERMS_AND_CONDITIONS: {
    href: '/terms-and-conditions',
    name: 'Terms & Conditions'
  },
  CONTACT: {
    href: '/contact-us',
    name: 'Contact us'
  },
  PRODUCT_DISCOVERY: {
    href: '/product-discovery',
    name: 'Product Discovery'
  }
};

const WE_CAN_HELP_ID = 'we-can-help-with';

export default { ROUTES, WE_CAN_HELP_ID };
