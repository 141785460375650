import styled, { css } from 'styled-components';

import { AppImageProps } from './AppImage';

export const StyledWrapper = styled.div<{
  isLoad: boolean;
  hasFade: AppImageProps['hasFade'];
  isDisplayFlex: AppImageProps['isDisplayFlex'];
  justifyContent?: AppImageProps['justifyContent'];
}>`
  justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'unset')};
  text-align: center;

  ${({ hasFade }) =>
    hasFade &&
    css<{ isLoad: boolean }>`
      opacity: 0;
      transition: 200ms ease-in-out 250ms;

      ${({ isLoad }) =>
        isLoad &&
        css`
          opacity: 1;
        `};
    `};

  ${({ isDisplayFlex }) =>
    isDisplayFlex &&
    css`
      display: flex;
    `};
`;
