import Script from 'next/script';
import React from 'react';

interface PageEventProps {
  event: string;
  page: string;
  title: string;
}

export const GTMPageView = (url: string): PageEventProps => {
  const pageEvent: PageEventProps = {
    event: 'pageview',
    page: url,
    title: document.title
  };

  /* eslint-disable @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  if (window && window.dataLayer) {
    // @ts-ignore
    window.dataLayer.push(pageEvent);
  }
  /* eslint-enable @typescript-eslint/ban-ts-comment */

  return pageEvent;
};

const GTM_ID = '__gtm';
const GTM_CONTAINER_ID = 'GTM-PMH6LHT';

export const GTMscript = (
  <>
    <Script id={GTM_ID} strategy="afterInteractive">
      {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GTM_CONTAINER_ID}');`}
    </Script>
  </>
);

export const renderGTMNoscript = (): JSX.Element => (
  <noscript
    dangerouslySetInnerHTML={{
      __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_CONTAINER_ID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
    }}
  />
);
