export const getLocaleFullName = (locale) => {
  switch (locale) {
    case 'en':
      return 'English';
    case 'de':
      return 'Deutsch';
    case 'pl':
      return 'Polski';
    default:
      return '';
  }
};
